import { fork } from "redux-saga/effects";
import { calendarSaga } from "./components/CreateCalendar/CalendarSaga";
import { roomsSaga } from "./components/CreateCalendar/components/RoomSettings/roomsSaga";
import { servicesSaga } from "./components/CreateCalendar/components/ServiceSettings/servicesSaga";
import { eventSaga } from "./components/Event/EventSaga";

export function* rootSaga() {
  yield fork(servicesSaga);
  yield fork(roomsSaga);
  yield fork(calendarSaga);
  yield fork(eventSaga);
}
