import { Flex, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import { t } from "i18next";
import s from "../InputStyle.module.scss";

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
}

export const NoteInput = ({ value, onChange, onDelete }: Props) => {
  return (
    <Flex className={s.formBox}>
      <Text className={s.label}>{t("Note")}</Text>
      <InputGroup>
        <Input
          className={s.input}
          value={value}
          onChange={onChange}
          placeholder={t("Enter a note")}
          _placeholder={{ color: "#8592A3", fontSize: "15px" }}
        />
        <InputRightElement>
          {value && <CrossIcon boxSize={1} cursor={"pointer"} className={s.crossIcon} onClick={onDelete} />}
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};
