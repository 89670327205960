import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApplicationState } from "../../ApplicationState";
import { CalendarState } from "./CalendarState";
import { CalendarModel } from "./CalendarModel";

const initialState: CalendarState = {
  calendar: {} as CalendarModel,
  isLoading: false,
};

export const calendarSlice = createSlice({
  name: "calendarSlice",
  initialState,
  reducers: {
    getCalendar: state => {
      return {
        ...state,
        isLoading: true,
      };
    },
    getCalendarCompleted: (state, action: PayloadAction<CalendarModel>) => {
      return {
        ...state,
        calendar: action.payload,
        isLoading: false,
      };
    },
    createCalendar: (state, action: PayloadAction<CalendarModel>) => {
      return {
        ...state,
        calendar: action.payload,
      };
    },
  },
});

export const { getCalendar, getCalendarCompleted, createCalendar } = calendarSlice.actions;

export const selectCalendar = (state: ApplicationState) => state.app.calendarState.calendar;
export const selectIsLoading = (state: ApplicationState) => state.app.calendarState.isLoading;
