import { Menu, MenuButton, MenuItem, MenuList, Button, Flex, Text, Grid, Icon } from "@chakra-ui/react";
import { useState } from "react";
import { ReactComponent as ArrowDrop } from "../../../../assets/icons/ArrowDrop.svg";
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, addDays, isSameMonth } from "date-fns";
import { ReactComponent as Left } from "../../../../assets/icons/LeftArrow.svg";
import { ReactComponent as Right } from "../../../../assets/icons/RightArrow.svg";
import s from "./EventDatepicker.module.scss";
import { t } from "i18next";

interface TestProps {
  selectedDate: Date | null;
  onDateSelect: (date: Date) => void;
}

export const EventDatepicker = ({ selectedDate, onDateSelect }: TestProps) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(day => t(day));

  const nextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));
  const prevMonth = () => setCurrentMonth(subMonths(currentMonth, 1));

  const getLocalizedMonth = (date: Date) => {
    const month = format(date, "MMMM");
    return `${t(month)} ${format(date, "yyyy")}`;
  };

  const getFullDate = (date: Date) => {
    const month = format(date, "MMMM");
    return `${format(date, "dd")} ${t(month)} ${format(date, "yyyy")}`;
  };

  const getDaysInMonth = () => {
    const startDate = startOfWeek(startOfMonth(currentMonth));
    const endDate = endOfMonth(currentMonth);
    const days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        days.push(day);
        day = addDays(day, 1);
      }
    }
    return days;
  };

  const daysInMonth = getDaysInMonth();

  const handleDayClick = (day: Date) => {
    onDateSelect(day);
  };

  return (
    <Menu matchWidth>
      <MenuButton
        as={Button}
        variant="dominoDefaultMenuBtn"
        _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
        rightIcon={<ArrowDrop />}
      >
        {selectedDate ? getFullDate(selectedDate) : t("Select date")}
      </MenuButton>
      <MenuList
        css={{
          "&::-webkit-scrollbar": {
            width: "0",
          },
        }}
      >
        <Flex className={s.header}>
          <Icon cursor="pointer" boxSize="20px" as={Left} onClick={prevMonth} />
          <Text>{getLocalizedMonth(currentMonth)}</Text>
          <Icon cursor="pointer" boxSize="20px" as={Right} onClick={nextMonth} />
        </Flex>
        <Grid templateColumns="repeat(7, 1fr)" textAlign="center" gap={2} p="8px 0px">
          {daysOfWeek.map(day => (
            <Text fontSize="12px" color="#3E5E95" key={day}>
              {day}
            </Text>
          ))}
        </Grid>
        <Grid templateColumns="repeat(7, 1fr)" textAlign="center" justifyContent="center" gap={2} p="8px 0px">
          {daysInMonth.map((day, index) => {
            const isCurrentMonth = isSameMonth(day, currentMonth);
            return (
              <Flex key={index} w="100%" align={"center"} justifyContent={"center"}>
                <MenuItem
                  onClick={() => handleDayClick(day)}
                  bg={selectedDate && format(day, "dd/MM/yyyy") === format(selectedDate, "dd/MM/yyyy") ? "#6D5BF7" : undefined}
                  color={selectedDate && format(day, "dd/MM/yyyy") === format(selectedDate, "dd/MM/yyyy") ? "white" : undefined}
                  borderRadius="md"
                  _hover={{ bg: isCurrentMonth ? "blue.100" : "gray.200" }}
                  opacity={isCurrentMonth ? 1 : 0.4}
                  justifyContent="center"
                  w="30px"
                >
                  {format(day, "d")}
                </MenuItem>
              </Flex>
            );
          })}
        </Grid>
      </MenuList>
    </Menu>
  );
};
