import { Flex, Menu, MenuButton, MenuItem, MenuList, Button, Text } from "@chakra-ui/react";
import { ReactComponent as ArrowDrop } from "../../../assets/icons/ArrowDrop.svg";
import s from "../InputStyle.module.scss";
import { t } from "i18next";

interface Props {
  timeOptions: { value: number; label: string }[];
  selectedDurationLabel: string;
  onDurationChange: (minutes: number, displayValue: string) => void;
}

export const DurationInput = ({ timeOptions, selectedDurationLabel, onDurationChange }: Props) => {
  return (
    <Flex className={s.formBox} w="50%">
      <Text className={s.label}>{t("Duration")}</Text>
      <Menu variant="dominoBroadcastMenu" matchWidth={true}>
        <MenuButton
          variant="dominoDefaultMenuBtn"
          _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
          as={Button}
          rightIcon={<ArrowDrop />}
        >
          {selectedDurationLabel}
        </MenuButton>
        <MenuList
          maxH="260px"
          overflow={"auto"}
          css={{
            "&::-webkit-scrollbar": {
              width: "0",
            },
          }}
        >
          {timeOptions.map(({ value, label }, index) => (
            <MenuItem key={index} color="black" onClick={() => onDurationChange(value, label)}>
              {label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};
