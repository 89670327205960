import { RefObject, useState } from "react";
import { Text, Button, Flex } from "@chakra-ui/react";
import s from "./SchedulerHeader.module.scss";
import { useNavigate } from "react-router-dom";
import { SchedulerRef } from "@anthill/react-scheduler/types";
import { t } from "i18next";
import { EventModal } from "../../Event/components/EventModal/EventModal";
import { RoomsModel } from "../../CreateCalendar/components/RoomSettings/RoomsModel";

interface Props {
  calendarRef: RefObject<SchedulerRef>;
  rooms: RoomsModel[];
}

export const SchedulerHeader = ({ calendarRef, rooms }: Props) => {
  const nav = useNavigate();
  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  const handleOpen = () => setIsModalShown(true);
  const handleClose = () => setIsModalShown(false);

  return (
    <Flex className={s.headerContainer}>
      <Button variant="dominoOutlineViolet" onClick={() => nav("/calendar")}>
        <Text fontSize="16px" lineHeight="16px">
          + {t("Room")}
        </Text>
      </Button>
      <Button variant="dominoViolet" onClick={handleOpen}>
        <Text fontSize="16px" lineHeight="16px">
          + {t("Event")}
        </Text>
      </Button>
      {isModalShown && <EventModal onCancel={handleClose} event={null} rooms={rooms} />}
    </Flex>
  );
};
