import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import { DominoCalendarState } from "./ApplicationState";
import { servicesSlice } from "./components/CreateCalendar/components/ServiceSettings/servicesSlice";
import { calendarSlice } from "./components/CreateCalendar/CalendarSlice";
import { roomsSlice } from "./components/CreateCalendar/components/RoomSettings/roomsSlice";
import { eventSlice } from "./components/Event/EventSlice";

export const rootReducer = (state: DominoCalendarState | undefined, action: AnyAction) => {
  const combinedReducers = combineReducers<DominoCalendarState>({
    roomsState: roomsSlice.reducer,
    servicesState: servicesSlice.reducer,
    calendarState: calendarSlice.reducer,
    eventState: eventSlice.reducer,
  });
  return combinedReducers(state, action);
};
