import {
  Box,
  Button,
  ChakraProvider,
  Checkbox,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import s from "./EventModal.module.scss";
import { theme, useAppDispatch, useGA } from "@anthill/domino-ui-base";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../../store";
import { createEvent, deleteEvent, updateEvent } from "../../EventSlice";
import { ReactComponent as ArrowDrop } from "../../../../assets/icons/ArrowDrop.svg";
import { getServices, selectServices } from "../../../CreateCalendar/components/ServiceSettings/servicesSlice";
import { RoomsModel } from "../../../CreateCalendar/components/RoomSettings/RoomsModel";
import { ServiceOptionsModel, ServicesModel } from "../../../CreateCalendar/components/ServiceSettings/ServicesModel";
import "react-datepicker/dist/react-datepicker.css";
import { EventModel } from "../../EventModel";
import { NameInput } from "../../../modalItems/NameInput/NameInput";
import { FooterButtons } from "../../../modalItems/FooterButtons/FooterButtons";
import { NoteInput } from "../../../modalItems/NoteInput/NoteInput";
import { validateNameValue, ValidationErrors } from "../../../modalItems/validations/validations";
import { SchedulerHelpers } from "@anthill/react-scheduler/types";
import { EventDatepicker } from "../EventDatepicker/EventDatepicker";
import { CloseModalButton } from "../../../closeModalButton/closeModalButton";

interface Props {
  onCancel: () => void;
  rooms: RoomsModel[];
  event: EventModel | null;
  schedulerData?: SchedulerHelpers;
}

export const EventModal = ({ onCancel, rooms, event, schedulerData }: Props) => {
  const dispatch = useAppDispatch();
  const services = useAppSelector(selectServices);
  const trackEvent = useGA("EventModal");
  const isEditMode = !!event;

  const selectedRoomInit =
    rooms.find(room => room.roomId === schedulerData?.roomId || room.roomId === event?.roomId) || rooms[0] || null;
  const startInit = (schedulerData && schedulerData.state.start.value) ?? (event && new Date(event.start)) ?? null;
  const endInit = (schedulerData && schedulerData.state.end.value) ?? (event && new Date(event.end)) ?? null;

  const [name, setName] = useState(event?.title || "");
  const [contactName, setContactName] = useState(event?.contact?.username || "");
  const [selectedRoom, setSelectedRoom] = useState<RoomsModel | null>(selectedRoomInit);
  const [selectedStatus, setSelectedStatus] = useState(event?.status || "Pending");
  const [selectedService, setSelectedService] = useState<ServicesModel | null>(event?.services[0] || services?.[0] || null);
  const [startDate, setStartDate] = useState<Date | null>(startInit);
  const [endDate, setEndDate] = useState<Date | null>(endInit);
  const [selectedTimeStart, setSelectedTimeStart] = useState("");
  const [selectedTimeEnd, setSelectedTimeEnd] = useState("");
  const [note, setNote] = useState(event?.note || "");
  const [SelectisOpen, setSelectIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<ServiceOptionsModel[]>([]);

  const handleOptionToggle = (option: ServiceOptionsModel) => {
    if (selectedOptions.findIndex(el => el.name === option.name) > -1) {
      setSelectedOptions(prev => prev.filter(o => o.name !== option.name));
    } else {
      setSelectedOptions(prev => [...prev, option]);
    }
  };

  const filteredServices = selectedRoom ? services?.filter(service => selectedRoom?.services?.includes(service.id)) : services;

  const hasServices = filteredServices && filteredServices.length > 0;

  const handleToggle = () => {
    if (hasServices) {
      setSelectIsOpen(!SelectisOpen);
    }
  };

  useEffect(() => {
    if (event) {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);

      setSelectedTimeStart(eventStart.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
      setSelectedTimeEnd(eventEnd.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
      setStartDate(new Date(event.start));
      setSelectedOptions(event.services[0]?.options || []);
    }
  }, [event]);

  const handleDateSelect = (date: Date) => {
    setStartDate(date);
  };

  const generateTimeOptions = () => {
    const times = [];
    for (let i = 0; i < 24 * 60; i += 15) {
      const hours = Math.floor(i / 60)
        .toString()
        .padStart(2, "0");
      const minutes = (i % 60).toString().padStart(2, "0");
      times.push(`${hours}:${minutes}`);
    }
    return times;
  };

  const timeOptions = generateTimeOptions();

  const [errors, setErrors] = useState<ValidationErrors>({
    nameValue: false,
    numberValue: false,
  });

  useEffect(() => {
    dispatch(getServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setSelectedService(event?.services[0] || null);
  }, [services, event]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
    setErrors(prevErrors => ({
      ...prevErrors,
      nameValue: validateNameValue(value),
    }));
  };

  const handleContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactName(e.target.value);
  };

  const handleServiceSelect = (service: ServicesModel) => {
    setSelectedService(service);
    setSelectedOptions([]);
  };

  const getGeneralEventData = (): EventModel => {
    return {
      event_id: event?.event_id || "",
      contact: {
        username: contactName || "",
        conversationId: event?.contact.conversationId || "",
      },
      start: startDate ? startDate.toISOString() : "",
      end: endDate ? endDate.toISOString() : "",
      roomId: selectedRoom?.roomId || "",
      note,
      status: selectedStatus,
      services: selectedService ? [{ ...selectedService, options: selectedOptions }] : [],
      title: name,
    };
  };
  const handleCreateEvent = () => {
    const event = getGeneralEventData();
    const startDateTime = new Date(startDate || "");
    const [startHours, startMinutes] = selectedTimeStart.split(":");
    startDateTime.setHours(+startHours, +startMinutes);

    const endDateTime = new Date(startDate || "");
    const [endHours, endMinutes] = selectedTimeEnd.split(":");
    endDateTime.setHours(+endHours, +endMinutes);

    const eventData = {
      ...event,
      event_id: event?.event_id ?? "",
      start: startDateTime.toISOString(),
      end: endDateTime.toISOString(),
    } as EventModel;

    isEditMode ? dispatch(updateEvent(eventData)) : dispatch(createEvent(eventData));
    handleCancel();
  };

  const handleCancel = () => {
    setName("");
    setStartDate(null);
    setEndDate(null);
    setSelectedRoom(rooms[0] || null);
    setSelectedStatus("Pending");
    setSelectedService(services?.[0] || null);
    setNote("");
    onCancel();
  };

  const handleDelete = () => {
    dispatch(deleteEvent(event?.event_id as "string"));
    handleCancel();
  };

  const filteredTimeOptionsForEnd = useMemo(() => {
    if (!selectedTimeStart) return timeOptions;
    const [startHours, startMinutes] = selectedTimeStart.split(":").map(Number);

    return timeOptions.filter(time => {
      const [endHours, endMinutes] = time.split(":").map(Number);
      return endHours > startHours || (endHours === startHours && endMinutes > startMinutes);
    });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }, [selectedTimeStart, timeOptions]);

  return (
    <ChakraProvider theme={theme}>
      <Modal isOpen={true} onClose={onCancel}>
        <ModalContent className={s.modal} boxShadow="none" border="1px solid #DAE1FB">
          <ModalHeader className={s.header}>{isEditMode ? t("Edit Event") : t("Create Event")}</ModalHeader>
          <CloseModalButton close={handleCancel} width="32px" height="32px" size="lg" />
          <ModalBody className={s.body}>
            <NameInput
              value={name}
              onChange={handleNameChange}
              isInvalid={errors.nameValue}
              errorMessage={t("Name can't be empty")}
              onDelete={() => {
                setName("");
                setErrors(prevErrors => ({
                  ...prevErrors,
                  nameValue: validateNameValue(""),
                }));
              }}
            />
            <Flex gap="16px" w="100%">
              <Flex className={s.formBox} w="50%">
                <Text className={s.label}>{t("Wroom")}</Text>
                <Menu variant="dominoBroadcastMenu" matchWidth={true} autoSelect={true}>
                  <MenuButton
                    as={Button}
                    variant="dominoDefaultMenuBtn"
                    _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
                    rightIcon={<ArrowDrop />}
                  >
                    {selectedRoom && (
                      <Flex align="center" gap="8px">
                        <Box className={s.color} bgColor={selectedRoom.color} /> {selectedRoom.name}
                      </Flex>
                    )}
                  </MenuButton>
                  <MenuList
                    maxH="260px"
                    overflow={"auto"}
                    css={{
                      "&::-webkit-scrollbar": {
                        width: "0",
                      },
                    }}
                  >
                    {rooms.map((room, index) => (
                      <MenuItem key={index} color="black" gap="8px" h="36px" onClick={() => setSelectedRoom(room)}>
                        <Box className={s.color} bgColor={room.color} />
                        {room.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
              <Flex className={s.formBox} w="50%">
                <Text className={s.label}>{t("Contact")}</Text>
                <Input
                  className={s.input}
                  placeholder={t("Enter a contact name")}
                  value={contactName}
                  onChange={handleContactChange}
                />
              </Flex>
            </Flex>
            <Flex className={s.formBox}>
              <Text className={s.label}>{t("Status")}</Text>
              <Menu variant="dominoBroadcastMenu" matchWidth={true} autoSelect={true}>
                <MenuButton
                  as={Button}
                  variant="dominoDefaultMenuBtn"
                  _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
                  rightIcon={<ArrowDrop />}
                >
                  {t(selectedStatus)}
                </MenuButton>
                <MenuList
                  maxH="260px"
                  overflow={"auto"}
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "0",
                    },
                  }}
                >
                  <MenuItem color="black" gap="8px" h="36px" onClick={() => setSelectedStatus("Pending")}>
                    {t("Pending")}
                  </MenuItem>
                  <MenuItem color="black" gap="8px" h="36px" onClick={() => setSelectedStatus("Confirmed")}>
                    {t("Confirmed")}
                  </MenuItem>
                  <MenuItem color="black" gap="8px" h="36px" onClick={() => setSelectedStatus("Cancelled")}>
                    {t("Cancelled")}
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Flex className={s.formBox} w="100%">
              <Text className={s.label}>{t("Date")}</Text>
              <EventDatepicker selectedDate={startDate} onDateSelect={handleDateSelect} />
            </Flex>
            <Flex gap="16px" w="100%">
              <Flex className={s.formBox} w="50%">
                <Text className={s.label}>{t("From")}</Text>
                <Menu variant="dominoBroadcastMenu" matchWidth>
                  <MenuButton
                    as={Button}
                    variant="dominoDefaultMenuBtn"
                    _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
                    rightIcon={<ArrowDrop />}
                  >
                    {selectedTimeStart ? selectedTimeStart : <Text color={"#8592A3"}>{t("Start time")}</Text>}
                  </MenuButton>
                  <MenuList
                    maxH="200px"
                    overflowY="auto"
                    css={{
                      "&::-webkit-scrollbar": {
                        width: "0",
                      },
                    }}
                  >
                    {timeOptions?.map(time => (
                      <MenuItem color="black" gap="8px" h="36px" key={time} onClick={() => setSelectedTimeStart(time)}>
                        {time}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
              <Flex className={s.formBox} w="50%">
                <Text className={s.label}>{t("To")}</Text>
                <Menu variant="dominoBroadcastMenu" matchWidth>
                  <MenuButton
                    as={Button}
                    variant="dominoDefaultMenuBtn"
                    _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
                    rightIcon={<ArrowDrop />}
                  >
                    {selectedTimeEnd ? selectedTimeEnd : <Text color={"#8592A3"}>{t("End time")}</Text>}
                  </MenuButton>
                  <MenuList
                    maxH="200px"
                    overflowY="auto"
                    css={{
                      "&::-webkit-scrollbar": {
                        width: "0",
                      },
                    }}
                  >
                    {filteredTimeOptionsForEnd?.map(time => (
                      <MenuItem color="black" gap="8px" h="36px" key={time} onClick={() => setSelectedTimeEnd(time)}>
                        {time}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
            <Flex className={s.formBox}>
              <Text className={s.label}>{t("Service")}</Text>
              <Menu
                variant="dominoBroadcastMenu"
                matchWidth={true}
                isOpen={SelectisOpen}
                onOpen={() => handleToggle()}
                onClose={() => setSelectIsOpen(false)}
              >
                <MenuButton
                  as={Button}
                  variant="dominoDefaultMenuBtn"
                  _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
                  rightIcon={<ArrowDrop />}
                  isDisabled={!hasServices}
                >
                  {selectedService ? selectedService.name : <Text color={"#8592A3"}>{t("Select service")}</Text>}
                </MenuButton>
                <MenuList
                  maxH="260px"
                  overflow={"auto"}
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "0",
                    },
                  }}
                >
                  {filteredServices?.map((service, index) => (
                    <MenuItem key={index} color="black" gap="8px" h="36px" onClick={() => handleServiceSelect(service)}>
                      {service.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Flex>
            {selectedService && selectedService?.options?.length > 0 && (
              <Flex className={s.formBox}>
                <Text className={s.label} color="#8592A3" fontSize="13px">
                  {t("Options")}
                </Text>
                <Menu variant="dominoBroadcastMenu" matchWidth>
                  <MenuButton
                    as={Button}
                    variant="dominoDefaultMenuBtn"
                    _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
                    rightIcon={<ArrowDrop />}
                  >
                    {selectedOptions?.length > 0 ? (
                      selectedOptions?.map(option => option?.name).join(", ")
                    ) : (
                      <Text color={"#8592A3"}>{t("Select options")}</Text>
                    )}
                  </MenuButton>
                  <MenuList
                    maxH="260px"
                    overflowY="auto"
                    css={{
                      "&::-webkit-scrollbar": {
                        width: "0",
                      },
                    }}
                  >
                    {selectedService.options?.map((option, index) => (
                      <MenuItem key={index} closeOnSelect={false} color={"black"}>
                        <Checkbox
                          isChecked={selectedOptions.some(selected => selected?.name === option?.name)}
                          onChange={() => handleOptionToggle(option)}
                          cursor="pointer"
                          variant="dominoGreen"
                          p="4px 0 4px"
                        >
                          {option.name}
                        </Checkbox>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
            )}
            <NoteInput onDelete={() => setNote("")} value={note} onChange={e => setNote(e.target.value)} />
          </ModalBody>
          <ModalFooter className={s.footer}>
            <FooterButtons
              id={event?.event_id}
              handleSave={handleCreateEvent}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
              name={name}
              errors={errors}
              isCapacity={true}
              trackEvent={trackEvent}
              eventName="createEvent"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};
