import { DayHours } from "@anthill/react-scheduler/types";
import { ScheduleModel } from "../../CreateCalendar/CalendarModel";
import { WeekDays } from "@anthill/react-scheduler/views/Month";

export function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

const DAYS_NUMBER: Record<string, WeekDays> = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0,
};
export function getWorkDays(schedule: ScheduleModel[]): WeekDays[] {
  const workDays: WeekDays[] = [];

  schedule.forEach(day => {
    if (day.isAvailable) {
      workDays.push(DAYS_NUMBER[day.dayOfWeek]);
    }
  });

  return workDays;
}

export function calculateWorkHours(schedule: ScheduleModel[]): { startHour: DayHours; endHour: DayHours } {
  let minimalHour!: number;
  let maximalHour!: number;

  schedule.forEach(day =>
    day.intervals.forEach(interval => {
      const startHour = Number(interval.beginTime.split(":")[0]);
      const endHours = interval.endTime.split(":");
      const forcedEndHours = Number(endHours[1]) > 0 ? Number(endHours[0]) + 1 : Number(endHours[0]);

      if (!minimalHour || startHour < minimalHour) {
        minimalHour = startHour;
      }

      if (!maximalHour || forcedEndHours > maximalHour) {
        maximalHour = forcedEndHours;
      }
    }),
  );

  return { startHour: minimalHour as DayHours, endHour: maximalHour as DayHours };
}
