import { baseApi } from "@anthill/domino-ui-base";
import { CalendarModel } from "./CalendarModel";

export const getCalendar = () => {
  return baseApi.httpGet(`/calendar`);
};

export const createCalendar = (calendar: CalendarModel) => {
  return baseApi.httpPost(`/calendar`, calendar);
};
