import { CloseButton } from "@chakra-ui/react";

interface Props {
  close: () => void;
  width?: string;
  height?: string;
  size?: "md" | "lg";
  top?: string;
  right?: string;
}

export const CloseModalButton = ({ close, width = "24px", height = "24px", size = "md", top, right }: Props) => {
  const offset = size === "md" ? "4px" : "12px";

  return (
    <CloseButton
      onClick={close}
      pos="absolute"
      top={top || offset}
      right={right || offset}
      w={width}
      h={height}
      size={size}
      color="darkGrey"
      borderRadius="50%"
      _hover={{ bgColor: "lightDeepBlue", color: "mainPurple" }}
    />
  );
};
