import { Flex, Menu, MenuButton, Button, MenuList, MenuItem, Icon } from "@chakra-ui/react";
import s from "./DurationTimeEditor.module.scss";
import { ReactComponent as addTimeIcon } from "../../assets/icons/addTimeIcon.svg";
import { ReactComponent as removeTimeIcon } from "../../assets/icons/TrashGrey.svg";
import { v1 as uuidv1 } from "uuid";

interface Props {
  addTime: (dayTime: string) => void;
  removeTime: (dayTime: string, index: number) => void;
  copyDay: (dayTime: string) => void;
  changeTime: (
    newTime: {
      beginTime: string;
      endTime: string;
    },
    index: number,
    dayTime: string,
  ) => void;
  timeMenu: { hours: number; minutes: number }[];
  label: string;
  index: number;
  time: {
    beginTime: string;
    endTime: string;
  };
  intersection: boolean;
}

export interface ChoisenTime {
  hours: number;
  minutes: number;
}

export default function DurationTimeEditor({
  timeMenu,
  addTime,
  removeTime,
  copyDay,
  changeTime,
  label,
  index,
  time,
  intersection,
}: Props) {
  const formatingTime = (el: { hours: number; minutes: number }) => {
    return `${el.hours > 9 ? el.hours : `0${el.hours}`}:${el.minutes > 9 ? el.minutes : `0${el.minutes}`}`;
  };

  const isStart = (elStart: string, elEnd: string) => {
    const start = new Date(`May 1,2019 ${elStart}`);
    const end = new Date(`May 1,2019 ${elEnd}`);

    return start > end;
  };

  const [hours, minutes] = time.beginTime.split(":").map(Number);
  const startInterval = {
    hours: hours,
    minutes: minutes,
  };

  return (
    <Flex gap="8px" sx={{ "& > :first-child": { marginTop: "2px" } }}>
      <Flex w="166px" className={`${s.menu} ${intersection ? s.intersection : ""}`} justify="space-between" align="center">
        <Flex align="center" gap="8px">
          <Menu variant="dominoDurationMenu">
            <MenuButton className={s.menuButton} as={Button}>
              {time.beginTime}
            </MenuButton>
            <MenuList
              w="84px"
              minW="84px"
              maxH="188px"
              overflow={"auto"}
              css={{
                "&::-webkit-scrollbar": {
                  width: "0",
                },
              }}
            >
              {timeMenu.map(el => (
                <MenuItem
                  onClick={() => {
                    changeTime(
                      {
                        beginTime: formatingTime(el),
                        endTime: isStart(formatingTime(el), time.endTime) ? formatingTime(el) : time.endTime,
                      },
                      index,
                      label,
                    );
                  }}
                  key={uuidv1()}
                >
                  {formatingTime(el)}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
        —
        <Flex align="center" gap="8px">
          <Menu variant="dominoDurationMenu">
            <MenuButton className={s.menuButton} as={Button}>
              {time.endTime}
            </MenuButton>
            <MenuList w="84px" minW="84px" maxH="188px" overflow="auto" css={{ "&::-webkit-scrollbar": { width: "0" } }}>
              {timeMenu.map(el => {
                const correctEl =
                  (el.hours === startInterval?.hours && el.minutes > startInterval?.minutes) || el.hours > startInterval?.hours;
                if (correctEl) {
                  return (
                    <>
                      <MenuItem
                        onClick={() => {
                          changeTime(
                            {
                              beginTime: isStart(time.beginTime, formatingTime(el)) ? formatingTime(el) : time.beginTime,
                              endTime: formatingTime(el),
                            },
                            index,
                            label,
                          );
                        }}
                        key={uuidv1()}
                      >
                        {formatingTime(el)}
                      </MenuItem>
                    </>
                  );
                } else {
                  return null;
                }
              })}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Flex align="center" gap="4px">
        <Icon
          opacity={index > 0 ? 0 : 1}
          color="#8592A3"
          _hover={{ color: "#089850", bg: "#F1F4F9", borderRadius: "4px" }}
          onClick={() => {
            if (!index) {
              addTime(label);
            }
          }}
          cursor={index > 0 ? "auto" : "pointer"}
          as={addTimeIcon}
          boxSize="24px"
        />
        <Icon
          color="#8592A3"
          _hover={{ color: "#EB5038", bg: "#F1F4F9", borderRadius: "4px" }}
          onClick={() => removeTime(label, index)}
          cursor="pointer"
          as={removeTimeIcon}
          boxSize="24px"
        />
      </Flex>
    </Flex>
  );
}
