import { Box, Button, Text, Icon, useDisclosure } from "@chakra-ui/react";
import s from "./RoomSettings.module.scss";
import { useAppDispatch } from "@anthill/domino-ui-base";
import { useEffect, useState } from "react";
import { getRooms, selectRooms } from "./roomsSlice";
import { useAppSelector } from "../../../../store";
import { ReactComponent as edit } from "../../../../assets/icons/edit.svg";
import { RoomModal } from "./RoomModal/RoomModal";
import { RoomsModel } from "./RoomsModel";
import { useTranslation } from "react-i18next";

export const RoomSettings = () => {
  const dispatch = useAppDispatch();
  const t = useTranslation().t;
  const rooms = useAppSelector(selectRooms);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRoom, setSelectedRoom] = useState<RoomsModel | null>(null);

  useEffect(() => {
    dispatch(getRooms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddRoom = () => {
    const emptyRoom: RoomsModel = {
      roomId: "",
      name: "",
      description: "",
      color: "",
      schedule: [],
      isAvailable: false,
      capacity: 0,
      imageUrl: "",
      isPromoted: false,
      services: [],
      note: "",
      isIndividualSchedule: false,
    };
    setSelectedRoom(emptyRoom);
    onOpen();
  };

  const handleEditRoom = (room: RoomsModel) => {
    setSelectedRoom(room);
    onOpen();
  };

  return (
    <Box className={s.settingsBlock}>
      <Box className={s.header}>{t("Wrooms")}</Box>
      <Box className={s.lines}>
        {rooms?.map(room => (
          <Box key={room.roomId} className={s.line}>
            <Box className={s.lineName}>
              {room.color && <Box className={s.color} bgColor={room.color} />}
              <Text overflow="hidden" textOverflow="ellipsis">
                {room.name}
              </Text>
            </Box>
            <Box className={s.discription}>
              <Text textOverflow="ellipsis" overflow="hidden" color={room.description ? "black" : "#8592A3"}>
                {room.description ? room.description : t("Description")}
              </Text>
            </Box>
            <Box onClick={() => handleEditRoom(room)} cursor="pointer">
              <Icon as={edit} color="#8592A3" alt="EditIcon" boxSize="20px" _hover={{ color: "#3E5E95" }} />
            </Box>
          </Box>
        ))}
        <Box p="16px 24px 16px 16px">
          <Button variant="dominoViolet" onClick={handleAddRoom}>
            {t("Add a wroom")}
          </Button>
        </Box>
      </Box>
      {selectedRoom && <RoomModal rooms={selectedRoom} isOpen={isOpen} onClose={onClose} />}
    </Box>
  );
};
