import { Flex, Text } from "@chakra-ui/react";
import s from "./Header.module.scss";
import { BackButton } from "../backButton/BackButton";
import { useNavigate } from "react-router-dom";

interface Props {
  headName?: string;
}

export const Header = ({ headName }: Props) => {
  const nav = useNavigate();

  const redirect = () => nav(`/`);

  return (
    <Flex className={s.container}>
      <Flex gap={"8px"}>
        <BackButton onButtonClick={redirect} />
        <Text className={s.headName} fontSize={"20px"}>
          {headName}
        </Text>
      </Flex>
    </Flex>
  );
};
