import { Flex, Input, Text } from "@chakra-ui/react";
import { t } from "i18next";
import s from "../InputStyle.module.scss";

interface Props {
  value: number | string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CostInput = ({ value, onChange }: Props) => {
  const placeholder = "0 " + t("$");

  return (
    <Flex className={s.formBox} w="50%">
      <Text className={s.label}>{t("Cost")}</Text>
      <Input type={typeof value} value={value} onChange={onChange} placeholder={placeholder} />
    </Flex>
  );
};
