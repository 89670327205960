import { Box } from "@chakra-ui/react";
import s from "./CreateCalendar.module.scss";
import { MainSettings } from "./components/MainSettings/MainSettings";
import { Header } from "../Header/Header";
import { RoomSettings } from "./components/RoomSettings/RoomSettings";
import { ServiceSettings } from "./components/ServiceSettings/ServiceSettings";
import { t } from "i18next";
import { useAppDispatch } from "@anthill/domino-ui-base";
import { useAppSelector } from "../../store";
import { getCalendar, selectCalendar } from "./CalendarSlice";
import { useEffect } from "react";

export const CreateCalendar = () => {
  const dispatch = useAppDispatch();
  const calendar = useAppSelector(selectCalendar);

  useEffect(() => {
    dispatch(getCalendar());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const name = calendar?.schedule?.length === 0 ? t("New calendar") : t("Calendar");

  return (
    <>
      <Header headName={name} />
      <Box className={s.main}>
        <MainSettings />
        <RoomSettings />
        <ServiceSettings />
      </Box>
    </>
  );
};
