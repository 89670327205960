export interface ValidationErrors {
  nameValue: boolean;
  numberValue: boolean;
}

export const validateNameValue = (nameValue: string): boolean => {
  return nameValue.trim() === "";
};

export const validateNumberValue = (numberValue: number): boolean => {
  return numberValue < 0 || isNaN(numberValue as number);
};
