import { call, put, select, takeLatest } from "redux-saga/effects";
import * as ServicesApi from "./ServicesApi";
import { ServicesData } from "./ServicesData";
import { ServicesModel } from "./ServicesModel";
import { PayloadAction } from "@reduxjs/toolkit";
import { selectServices, servicesSlice } from "./servicesSlice";
import { handleException, loadingSlice } from "@anthill/domino-ui-base";

export function* servicesSaga() {
  yield takeLatest(servicesSlice.actions.getServices, getServices);
  yield takeLatest(servicesSlice.actions.createService, createService);
  yield takeLatest(servicesSlice.actions.getCurrentService, getCurrentService);
  yield takeLatest(servicesSlice.actions.updateService, updateService);
  yield takeLatest(servicesSlice.actions.deleteService, deleteService);
}

export function* getServices() {
  try {
    yield put(loadingSlice.actions.addLoadingState("getServices"));
    const data: ServicesData[] = yield call(ServicesApi.getServices);
    const model: ServicesModel[] = [...data];
    if (model.length > 0) {
      yield put(servicesSlice.actions.getServicesCompleted(model));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(loadingSlice.actions.completedLoadingState("getServices"));
  }
}

function* createService(action: PayloadAction<ServicesModel>) {
  try {
    const newService: ServicesModel = yield call(ServicesApi.createService, action.payload);
    const currentService: ServicesModel[] = yield select(selectServices);
    const updatedService = [...(currentService || []), newService];
    yield put(servicesSlice.actions.getServicesCompleted(updatedService));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* getCurrentService(action: PayloadAction<string>) {
  try {
    const data: ServicesData = yield call(ServicesApi.getCurrentService, action.payload);
    const model: ServicesModel = data;
    yield put(servicesSlice.actions.getCurrentServiceCompleted(model));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* updateService(action: PayloadAction<ServicesModel>) {
  try {
    yield call(ServicesApi.updateService, action.payload);
    const currentServices: ServicesModel[] = yield select(selectServices);
    const updatedServices = currentServices.map(service => (service.id === action.payload.id ? action.payload : service));
    yield put(servicesSlice.actions.getServicesCompleted(updatedServices));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* deleteService(action: PayloadAction<{ serviceId: string }>) {
  try {
    yield call(ServicesApi.deleteService, action.payload.serviceId);
    const currentServices: ServicesModel[] = yield select(selectServices);
    const updatedServices = currentServices.filter(srvice => srvice.id !== action.payload.serviceId);
    yield put(servicesSlice.actions.getServicesCompleted(updatedServices));
  } catch (e: unknown) {
    yield handleException(e);
  }
}
