import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApplicationState } from "../../ApplicationState";
import { EventState } from "./EventState";
import { EventModel } from "./EventModel";

const initialState: EventState = {
  events: [],
};

export const eventSlice = createSlice({
  name: "eventSlice",
  initialState,
  reducers: {
    getEvents: (state, action: PayloadAction<{ start: string; end: string }>) => {
      return {
        ...state,
      };
    },
    getEventsCompleted: (state, action: PayloadAction<EventModel[]>) => {
      return {
        ...state,
        events: action.payload,
      };
    },
    createEvent: (state, action: PayloadAction<EventModel>) => {
      return {
        ...state,
      };
    },
    createEventCompleted: (state, action: PayloadAction<EventModel>) => {
      return {
        ...state,
        events: [...state.events, action.payload],
      };
    },
    updateEvent: (state, action: PayloadAction<EventModel>) => {
      return {
        ...state,
      };
    },
    updateEventCompleted: (state, action: PayloadAction<EventModel>) => {
      return {
        ...state,
        events: [...state.events.filter(event => event.event_id !== action.payload.event_id), action.payload],
      };
    },
    deleteEvent: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    deleteEventCompleted: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        events: state.events.filter(event => event.event_id !== action.payload),
      };
    },
  },
});

export const {
  getEvents,
  getEventsCompleted,
  createEvent,
  createEventCompleted,
  deleteEvent,
  deleteEventCompleted,
  updateEvent,
  updateEventCompleted,
} = eventSlice.actions;

export const selectEvents = (state: ApplicationState) => state.app.eventState.events;
