import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  Button,
  Flex,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { useState } from "react";
import s from "../RoomModal.module.scss";
import { t } from "i18next";
import { RoomsModel } from "../../RoomsModel";
import WorkingTimeContainerRoom from "../../../../../WorkingTimeContainer/WorkingTimeContainerRoom";
import { CloseModalButton } from "../../../../../closeModalButton/closeModalButton";

interface SchedulePopoverProps {
  roomData: RoomsModel;
  onDataChangeRoom: (updatedRoom: RoomsModel) => void;
  isScheduleActive: boolean;
  handleCheckboxChange: () => void;
}

const SchedulePopover = ({ roomData, onDataChangeRoom, isScheduleActive, handleCheckboxChange }: SchedulePopoverProps) => {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const openPopover = () => setPopoverIsOpen(true);
  const closePopover = () => setPopoverIsOpen(false);

  const sortWorkingTime = roomData?.schedule;

  return (
    <Flex className={s.formBox}>
      <Text className={s.label}>{t("Working time")}</Text>
      <Flex>
        <Popover isOpen={popoverIsOpen} onClose={closePopover} placement="left">
          <PopoverTrigger>
            <Flex w="100%">
              {roomData.schedule.length === 0 && (
                <Button
                  onClick={() => {
                    openPopover();
                    onDataChangeRoom({
                      ...roomData,
                      isAvailable: true,
                      schedule: [
                        {
                          dayOfWeek: "Monday",
                          isAvailable: true,
                          intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                        },
                        {
                          dayOfWeek: "Tuesday",
                          isAvailable: true,
                          intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                        },
                        {
                          dayOfWeek: "Wednesday",
                          isAvailable: true,
                          intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                        },
                        {
                          dayOfWeek: "Thursday",
                          isAvailable: true,
                          intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                        },
                        {
                          dayOfWeek: "Friday",
                          isAvailable: true,
                          intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                        },
                        {
                          dayOfWeek: "Saturday",
                          isAvailable: false,
                          intervals: [],
                        },
                        {
                          dayOfWeek: "Sunday",
                          isAvailable: false,
                          intervals: [],
                        },
                      ],
                    });
                  }}
                  className={s.addButton}
                  w="100%"
                >
                  {t("Add a schedule")}
                </Button>
              )}
              {roomData?.schedule?.length !== 0 && (
                <Flex flexWrap="wrap" className={s.wrapper} onClick={openPopover} cursor={"pointer"}>
                  <Checkbox
                    isChecked={isScheduleActive}
                    onChange={handleCheckboxChange}
                    cursor="pointer"
                    variant="dominoGreen"
                    p="4px 0 4px"
                    mb="8px"
                  >
                    <Text color="black">{t("Individual schedule")}</Text>
                  </Checkbox>
                  <Flex flexDirection="column" w="100%">
                    <Flex flexDirection="column" gap="4px">
                      {sortWorkingTime &&
                        sortWorkingTime.map(el => {
                          if (el.intervals?.length !== 0) {
                            return (
                              <Flex gap="12px" key={el.dayOfWeek}>
                                <Text minW="40px" color="#8592A3">
                                  {t(`${el.dayOfWeek?.slice(0, 3)}`)}
                                </Text>
                                <Flex flexWrap="wrap" gap="4px">
                                  {el.intervals?.map((time, index) => (
                                    <Text key={index} color="#3E5E95">{`${time.beginTime} – ${time.endTime}${
                                      index !== el.intervals?.length - 1 ? "," : ""
                                    }`}</Text>
                                  ))}
                                </Flex>
                              </Flex>
                            );
                          }
                          return null;
                        })}
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </PopoverTrigger>
          <PopoverContent maxW="328px" mr="35px">
            <PopoverArrow />
            <CloseModalButton close={closePopover} width="32px" height="32px" size="lg" top="8px" right="8px" />
            <PopoverHeader fontWeight="700" p="12px 24px">
              {t("Working time")}
            </PopoverHeader>
            <PopoverBody
              p="24px"
              maxH="400px"
              overflow="auto"
              css={{
                "&::-webkit-scrollbar": {
                  width: "0",
                },
              }}
            >
              {popoverIsOpen && <WorkingTimeContainerRoom onDataChange={onDataChangeRoom} rooms={roomData} />}
            </PopoverBody>
            <PopoverFooter pt="24px" border="none">
              <Flex justify="space-between">
                <Button
                  onClick={() => {
                    onDataChangeRoom({
                      ...roomData,
                      isAvailable: false,
                      schedule: [],
                    });
                  }}
                  variant="dominoOutlineRed"
                >
                  {t("Delete")}
                </Button>
                <Button onClick={closePopover} variant="dominoViolet">
                  {t("Done")}
                </Button>
              </Flex>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </Flex>
    </Flex>
  );
};

export default SchedulePopover;
