import { Flex, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import { t } from "i18next";
import s from "../InputStyle.module.scss";

interface NameInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isInvalid: boolean;
  errorMessage: string;
  onDelete: () => void;
}

export const NameInput = ({ value, onChange, isInvalid, errorMessage, onDelete }: NameInputProps) => {
  return (
    <Flex className={s.formBox}>
      <Text className={s.label}>{t("Name")}</Text>
      <Flex flexDir="column" gap="4px">
        <InputGroup>
          <Input
            className={s.input}
            value={value}
            onChange={onChange}
            isInvalid={isInvalid}
            errorBorderColor="red.500"
            placeholder={t("Enter a name")}
          />
          <InputRightElement>
            {value && <CrossIcon boxSize={1} cursor={"pointer"} className={s.crossIcon} onClick={onDelete} />}
          </InputRightElement>
        </InputGroup>
        {isInvalid && <Text className={s.error}>{errorMessage}</Text>}
      </Flex>
    </Flex>
  );
};
