import { ServiceOptionsModel } from "../../CreateCalendar/components/ServiceSettings/ServicesModel";

export function formatOptions(options: ServiceOptionsModel[] | false): string | false {
  return (
    options &&
    options
      .map((el, index) => {
        return index === 0 ? el.name : `, ${el.name.toLowerCase()}`;
      })
      .join("")
  );
}
