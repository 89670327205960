import { t } from "i18next";

const getHourLabel = (hours: number) => {
  if (hours === 1) return t("1hour");
  if (hours >= 2 && hours <= 4) return t("2hours");
  return t("3hours");
};

export const generateTimeOptions = (option: boolean) => {
  const options = [];

  if (option) {
    options.push({ label: `0 ${t("minutes")}`, value: 0 });
  }

  for (let i = 15; i <= 120; i += 15) {
    const hours = Math.floor(i / 60);
    const minutes = i % 60;
    const hourLabel = hours > 0 ? `${hours} ${getHourLabel(hours)}` : "";
    const minuteLabel = minutes > 0 ? `${minutes} ${t("minutes")}` : "";
    options.push({ label: `${hourLabel} ${minuteLabel}`.trim(), value: i });
  }

  for (let i = 150; i <= 240; i += 30) {
    const hours = Math.floor(i / 60);
    const minutes = i % 60;
    const hourLabel = `${hours} ${getHourLabel(hours)}`;
    const minuteLabel = minutes > 0 ? `${minutes} ${t("minutes")}` : "";
    options.push({ label: `${hourLabel} ${minuteLabel}`.trim(), value: i });
  }

  for (let i = 300; i <= 480; i += 60) {
    const hours = Math.floor(i / 60);
    const hourLabel = `${hours} ${getHourLabel(hours)}`;
    options.push({ label: hourLabel, value: i });
  }

  options.push({ label: t("Day"), value: 1440 });

  return options;
};

export const formatDuration = (minutes: number) => {
  if (minutes === 1440) {
    return t("Day");
  }

  if (minutes === 0) {
    return `0 ${t("minutes")}`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const hourLabel = hours > 0 ? `${hours} ${getHourLabel(hours)}` : "";
  const minuteLabel = remainingMinutes > 0 ? `${remainingMinutes} ${t("minutes")}` : "";

  return [hourLabel, minuteLabel].filter(Boolean).join(" ");
};
