import ReactGA from "react-ga4";
import { BaseAppSettings } from "../BaseAppSettings";

declare const appSettings: BaseAppSettings;

export const useGA = (category: string) => {
  const trackEvent = (action: string, label?: string, data?: unknown) => {
    if (appSettings.GAIds) {
      if (data != null) {
        const event_params = {
          category,
          label,
          ...data,
        };

        ReactGA.event(action, event_params);
      } else {
        ReactGA.event({ category, action, label });
      }
    }
  };
  return trackEvent;
};
