import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Routes } from "react-router-dom";
import "./App.css";
import { AuthFrameContainer, NotificationContainer } from "@anthill/domino-ui-base";
import { HomePage } from "./components/HomePage/HomePage";
import { CreateCalendar } from "./components/CreateCalendar/CreateCalendar";

export const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/*"
          element={
            <Routes>
              <Route path="/" element={<HomePage />} />

              <Route path="/calendar">
                <Route path="" element={<CreateCalendar />} />
              </Route>
            </Routes>
          }
        ></Route>
      </>,
    ),
  );

  return (
    <>
      <RouterProvider router={router} />
      <AuthFrameContainer pluginName="calendar" />
      <NotificationContainer />
    </>
  );
};
