import { call, put, takeLatest } from "redux-saga/effects";
import * as CalendarApi from "./CalendarApi";
import { handleException, loadingSlice } from "@anthill/domino-ui-base";
import { calendarSlice } from "./CalendarSlice";
import { CalendarData } from "./CalendarData";
import { CalendarModel } from "./CalendarModel";
import { PayloadAction } from "@reduxjs/toolkit";

export function* calendarSaga() {
  yield takeLatest(calendarSlice.actions.getCalendar, getCalendar);
  yield takeLatest(calendarSlice.actions.createCalendar, createCalendar);
}

export function* getCalendar() {
  try {
    yield put(loadingSlice.actions.addLoadingState("getCalendar"));
    const data: CalendarData = yield call(CalendarApi.getCalendar);
    const model: CalendarModel = data;
    yield put(calendarSlice.actions.getCalendarCompleted(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(loadingSlice.actions.completedLoadingState("getCalendar"));
  }
}

function* createCalendar(action: PayloadAction<CalendarModel>) {
  try {
    yield call(CalendarApi.createCalendar, action.payload);
    yield put(calendarSlice.actions.getCalendar());
  } catch (e: unknown) {
    yield handleException(e);
  }
}
