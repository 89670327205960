import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ServicesModel } from "./ServicesModel";
import { ServicesState } from "./ServicesState";
import { ApplicationState } from "../../../../ApplicationState";

const initialState: ServicesState = {};

export const servicesSlice = createSlice({
  name: "servicesSlice",
  initialState,
  reducers: {
    getServices: state => {
      return {
        ...state,
      };
    },
    getServicesCompleted: (state, action: PayloadAction<ServicesModel[]>) => {
      return {
        ...state,
        services: action.payload,
      };
    },
    createService: (state, action: PayloadAction<ServicesModel>) => {
      return {
        ...state,
        newService: action.payload,
      };
    },
    getCurrentService: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    getCurrentServiceCompleted: (state, action: PayloadAction<ServicesModel>) => {
      return {
        ...state,
        currentService: action.payload,
      };
    },
    updateService: (state, action: PayloadAction<ServicesModel>) => {
      return {
        ...state,
      };
    },
    deleteService: (state, action: PayloadAction<{ serviceId: string }>) => {
      return {
        ...state,
      };
    },
    clearService: state => {
      return {
        ...state,
        currentService: undefined,
      };
    },
  },
});

export const { getServices, createService, getCurrentService, updateService, deleteService, clearService } =
  servicesSlice.actions;

export const selectServices = (state: ApplicationState) => state.app.servicesState.services;
export const selectCurrentService = (state: ApplicationState) => state.app.servicesState.currentService;
