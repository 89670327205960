import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from "@chakra-ui/react";
import s from "./ServiceModal.module.scss";
import { ServiceOptionsModel, ServicesModel } from "../ServicesModel";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useGA } from "@anthill/domino-ui-base";
import { createService, deleteService, updateService } from "../servicesSlice";
import { ReactComponent as ArrowDrop } from "../../../../../assets/icons/ArrowDrop.svg";
import { ReactComponent as Plus } from "../../../../../assets/icons/GreyPlus.svg";
import { formatDuration, generateTimeOptions } from "../utils/generateTimeOptions";
import { OptionModal } from "./OptionModal/OptionModal";
import { useTranslation } from "react-i18next";
import { NameInput } from "../../../../modalItems/NameInput/NameInput";
import { FooterButtons } from "../../../../modalItems/FooterButtons/FooterButtons";
import { ValidationErrors, validateNameValue } from "../../../../modalItems/validations/validations";
import { DurationInput } from "../../../../modalItems/DurationInput/DurationInput";
import { CostInput } from "../../../../modalItems/CostInput/CostInput";
import { CloseModalButton } from "../../../../closeModalButton/closeModalButton";
import { ReactComponent as edit } from "../../../../../assets/icons/edit.svg";
import { formatPriceWithSpaces } from "../../../../../utils/formatPriceWithSpaces";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  services: ServicesModel;
}

export const ServiceModal = ({ isOpen, onClose, services }: Props) => {
  const t = useTranslation().t;
  const dispatch = useAppDispatch();
  const trackEvent = useGA("ServiceModal");

  const [serviceData, setServiceData] = useState<ServicesModel>(services);
  const [name, setName] = useState(services.name || "");
  const [duration, setDuration] = useState(services.duration || 0);
  const [price, setPrice] = useState<number | string>(services.price || "");
  const [isOptionModalOpen, setIsOptionModalOpen] = useState(false);
  const [options, setOptions] = useState<ServiceOptionsModel[]>(services.options || []);
  const [optionToEdit, setOptionToEdit] = useState<ServiceOptionsModel | null>(null);

  const timeOptions = generateTimeOptions(false);

  const [selectedDurationLabel, setSelectedDurationLabel] = useState<string>(
    timeOptions.find(option => option.value === duration)?.label || timeOptions[0].label,
  );

  const [errors, setErrors] = useState<ValidationErrors>({
    nameValue: false,
    numberValue: false,
  });

  useEffect(() => {
    if (isOpen) {
      setServiceData(services);
      setName(services.name || "");
      const initialDuration = services.duration || timeOptions[0].value;
      const initialDurationLabel = timeOptions.find(option => option.value === initialDuration)?.label || timeOptions[0].label;
      setDuration(initialDuration);
      setSelectedDurationLabel(initialDurationLabel);
      setPrice(services.price || "");
      setOptions(services.options || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, services]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
    setErrors(prevErrors => ({
      ...prevErrors,
      nameValue: validateNameValue(value),
    }));
  };

  const handleOptionSave = (newOption: ServiceOptionsModel) => {
    if (optionToEdit) {
      const updatedOptions = options.map(option => (option.name === optionToEdit.name ? newOption : option));
      setOptions(updatedOptions);
    } else {
      setOptions([...options, newOption]);
    }
    setOptionToEdit(null);
  };

  const handleOptionEdit = (index: number) => {
    setOptionToEdit(options[index]);
    setIsOptionModalOpen(true);
  };

  const handleDurationChange = (minutes: number, displayValue: string) => {
    setDuration(minutes);
    setSelectedDurationLabel(displayValue);
  };

  const handleChangeCost = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      return setPrice("");
    }

    setPrice(parseInt(e.target.value));
  };

  const handleSave = () => {
    const updatedService = {
      ...serviceData,
      name,
      duration,
      price: Number(price),
      options,
    };
    if (serviceData.id) {
      dispatch(updateService(updatedService));
    } else {
      dispatch(createService(updatedService));
    }
    onClose();
    resetFields();
  };

  const handleCancel = () => {
    onClose();
    resetFields();
  };

  const handleDelete = () => {
    if (serviceData.id) {
      dispatch(deleteService({ serviceId: serviceData.id }));
    }
    onClose();
    resetFields();
  };

  const resetFields = () => {
    if (!services.id) {
      setServiceData({
        id: "",
        name: "",
        duration: 0,
        description: "",
        imageUrl: "",
        isPromoted: false,
        price: 0,
        options: [],
      });
      setDuration(timeOptions[0].value);
      setSelectedDurationLabel(timeOptions[0].label);
      setPrice(0);
      setErrors({
        nameValue: false,
        numberValue: false,
      });
    }
    setErrors({
      nameValue: false,
      numberValue: false,
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
        <ModalContent className={`${s.modal} ${isOptionModalOpen && s.modalOpacity}`}>
          <ModalHeader className={s.header}>{serviceData.id ? t("Edit a service") : t("Add a service")}</ModalHeader>
          <CloseModalButton close={handleCancel} width="32px" height="32px" size="lg" />
          <ModalBody className={s.body}>
            <NameInput
              onDelete={() => {
                setName("");
                setErrors(prevErrors => ({
                  ...prevErrors,
                  nameValue: validateNameValue(""),
                }));
              }}
              value={name}
              onChange={handleNameChange}
              isInvalid={errors.nameValue}
              errorMessage={t("Name can't be empty")}
            />
            <Flex gap="16px" w="100%">
              <DurationInput
                timeOptions={timeOptions}
                selectedDurationLabel={selectedDurationLabel}
                onDurationChange={handleDurationChange}
              />
              <CostInput value={price} onChange={handleChangeCost} />
            </Flex>
            <Flex className={s.formBox}>
              <Text className={s.label}>{t("Options")}</Text>
              <Menu variant="dominoBroadcastMenu" closeOnSelect={false} matchWidth>
                <MenuButton
                  as={Button}
                  variant="dominoDefaultMenuBtn"
                  _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
                  rightIcon={<ArrowDrop />}
                >
                  {options && options.length > 0 ? (
                    options.map(option => option.name).join(", ")
                  ) : (
                    <Text color="#8592A3">{t("Select options")}</Text>
                  )}
                </MenuButton>
                <MenuList
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "0",
                    },
                  }}
                >
                  {options.map((option, index) => (
                    <MenuItem key={index} p="8px 16px 8px 8px" color="black" cursor="default">
                      <Flex w="100%" align="center" justify="space-between">
                        <Flex gap="12px">
                          <Text maxW="180px" whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
                            {option.name}
                          </Text>
                          {option.optionValues.map((value, index) => (
                            <Flex key={index} gap="8px" color="gray" fontSize="12px">
                              <Text whiteSpace="nowrap">{formatDuration(value.duration)}</Text>
                              <Text>
                                {formatPriceWithSpaces(value.price)} {t("$")}
                              </Text>
                            </Flex>
                          ))}
                        </Flex>
                        <Icon
                          as={edit}
                          color="#8592A3"
                          alt="EditIcon"
                          boxSize="20px"
                          _hover={{ color: "#3E5E95" }}
                          onClick={() => handleOptionEdit(index)}
                        />
                      </Flex>
                    </MenuItem>
                  ))}
                  <MenuItem p="8px 16px 8px 8px" onClick={() => setIsOptionModalOpen(true)} color="black">
                    <Flex w="100%" gap="8px" align="center">
                      <Icon as={Plus} boxSize="20px" />
                      <Text>{t("Add an option")}</Text>
                    </Flex>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </ModalBody>
          <ModalFooter className={s.footer}>
            <FooterButtons
              id={serviceData.id}
              handleSave={handleSave}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
              name={name}
              errors={errors}
              isCapacity={true}
              trackEvent={trackEvent}
              eventName="createService"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <OptionModal
        options={options}
        setOptions={setOptions}
        optionToEdit={optionToEdit}
        isOpen={isOptionModalOpen}
        onClose={() => setIsOptionModalOpen(false)}
        onSave={handleOptionSave}
        setOptionToEdit={setOptionToEdit}
      />
    </>
  );
};
