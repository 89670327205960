import { Box, Button, Flex, Icon, Text, useDisclosure } from "@chakra-ui/react";
import s from "./ServiceSettings.module.scss";
import { useAppDispatch } from "@anthill/domino-ui-base";
import { useAppSelector } from "../../../../store";
import { useEffect, useState } from "react";
import { getServices, selectServices } from "./servicesSlice";
import { ReactComponent as edit } from "../../../../assets/icons/edit.svg";
import { ReactComponent as copy } from "../../../../assets/icons/copyIcon.svg";
import { ServiceModal } from "./ServiceModal/ServiceModal";
import { ServicesModel } from "./ServicesModel";
import { formatDuration } from "./utils/generateTimeOptions";
import { useTranslation } from "react-i18next";
import { formatPriceWithSpaces } from "../../../../utils/formatPriceWithSpaces";

export const ServiceSettings = () => {
  const t = useTranslation().t;
  const dispatch = useAppDispatch();
  const services = useAppSelector(selectServices);

  const [selectedService, setSelectedService] = useState<ServicesModel | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(getServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddService = () => {
    const emptyService: ServicesModel = {
      id: "",
      name: "",
      duration: 0,
      description: "",
      imageUrl: "",
      isPromoted: false,
      price: 0,
      options: [],
    };
    setSelectedService(emptyService);
    onOpen();
  };

  const handleEditService = (service: ServicesModel) => {
    setSelectedService(service);
    onOpen();
  };

  const handleCopyService = (service: ServicesModel) => {
    const copiedService = { ...service, id: "", name: `${service.name} (copy)` };
    setSelectedService(copiedService);
    onOpen();
  };

  function getOptionsText(count: number): string {
    const remainder = count % 100;
    if (remainder >= 11 && remainder <= 19) {
      return `${t("3options")}`;
    }
    switch (count % 10) {
      case 1:
        return `${t("1option")}`;
      case 2:
      case 3:
      case 4:
        return `${t("2options")}`;
      default:
        return `${t("3options")}`;
    }
  }

  return (
    <Flex className={s.settingsBlock}>
      <Box className={s.header}>{t("Services")}</Box>
      <Flex className={s.lines}>
        {services?.map(service => (
          <Box key={service.id} className={s.line}>
            <Flex className={s.lineName} gap="4px">
              <Text overflow="hidden" textOverflow="ellipsis">
                {service.name}
              </Text>
              {service.options.length > 0 && (
                <Text fontSize="13px" lineHeight="16px" color="#8592A3">
                  {service.options.length > 0
                    ? `${service.options.length} ${t("additional")} ${getOptionsText(service.options.length)}`
                    : ""}
                </Text>
              )}
            </Flex>
            <Flex className={s.discription}>
              <Flex className={s.duration}>
                <Text>{formatDuration(service.duration)}</Text>
              </Flex>
              <Flex className={s.cost}>
                <Text overflow={"hidden"} textOverflow={"ellipsis"} color={service.price > 0 ? "black" : "grey"}>
                  {formatPriceWithSpaces(service.price)} {t("$")}
                </Text>
              </Flex>
            </Flex>
            <Box cursor="pointer" onClick={() => handleEditService(service)}>
              <Icon as={edit} color="#8592A3" alt="EditIcon" boxSize="20px" _hover={{ color: "#3E5E95" }} />
            </Box>
            <Box cursor="pointer" onClick={() => handleCopyService(service)}>
              <Icon as={copy} color="#8592A3" alt="CopyIcon" boxSize="20px" _hover={{ color: "#3E5E95" }} />
            </Box>
          </Box>
        ))}
        <Box p="16px 24px 16px 16px">
          <Button variant="dominoViolet" onClick={handleAddService}>
            {t("Add a service")}
          </Button>
        </Box>
      </Flex>
      {selectedService && <ServiceModal services={selectedService} isOpen={isOpen} onClose={onClose} />}
    </Flex>
  );
};
