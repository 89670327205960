import { Badge, HTMLChakraProps } from "@chakra-ui/react";
import { t } from "i18next";

type StatusType = "Pending" | "Confirmed" | "Canceled";
type ColorPropertiesType = Required<Pick<HTMLChakraProps<"span">, "color" | "borderColor" | "bgColor">>;

interface EventStatusProps {
  status: StatusType;
}

const STATUS_COLORS: Record<StatusType, ColorPropertiesType> = {
  Pending: {
    color: "#A67F00",
    borderColor: "#A67F00",
    bgColor: "#FFF7E0",
  },
  Confirmed: {
    color: "#008500",
    borderColor: "#008500",
    bgColor: "#E6FAE6",
  },
  Canceled: {
    color: "#A60000",
    borderColor: "#A60000",
    bgColor: "#FFE6E6",
  },
};

const TEXT_STYLES: HTMLChakraProps<"span"> = {
  p: "2px 4px",
  height: "18px",
  fontSize: "12px",
  lineHeight: "14px",
  border: "1px solid",
  borderRadius: "4px",
  textTransform: "lowercase",
};

const EventStatus = ({ status }: EventStatusProps) => {
  const combinedStyles = { ...TEXT_STYLES, ...STATUS_COLORS[status] };

  return (
    <Badge alignItems="center" display="flex" {...combinedStyles}>
      {t(status)}
    </Badge>
  );
};

export { EventStatus };
