import { baseApi } from "@anthill/domino-ui-base";
import { EventModel } from "./EventModel";

export const getEvents = (start: string, end: string) => {
  return baseApi.httpGet(`/appointment?start=${start}&end=${end}`);
};

export const createEvent = (event: EventModel) => {
  return baseApi.httpPost(`/appointment`, event);
};

export const updateEvent = (event: EventModel) => {
  return baseApi.httpPut(`/appointment/${event.event_id}`, event);
};

export const deleteEvent = (event_id: string) => {
  return baseApi.httpDelete(`/appointment/${event_id}`);
};
