import { Button } from "@chakra-ui/react";
import { t } from "i18next";

interface Props {
  id?: string;
  handleSave: () => void;
  handleDelete: () => void;
  handleCancel: () => void;
  name: string;
  capacity?: number;
  errors: { nameValue: boolean; numberValue?: boolean };
  isCapacity?: boolean;
  trackEvent: (event: string) => void;
  eventName: string;
}

export const FooterButtons = ({
  id,
  handleSave,
  handleDelete,
  handleCancel,
  name,
  capacity,
  errors,
  isCapacity,
  trackEvent,
  eventName,
}: Props) => {
  return (
    <>
      {id ? (
        <>
          <Button variant="dominoOutlineRed" onClick={handleDelete}>
            {t("Delete")}
          </Button>
          <Button
            variant="dominoViolet"
            onClick={handleSave}
            isDisabled={
              !name ||
              (capacity === undefined && !isCapacity ? !capacity : false) ||
              errors.nameValue ||
              (errors.numberValue && !isCapacity)
            }
          >
            {t("Save")}
          </Button>
        </>
      ) : (
        <>
          <Button variant="dominoOutlineViolet" onClick={handleCancel}>
            {t("Cancel")}
          </Button>
          <Button
            variant="dominoViolet"
            onClick={() => {
              trackEvent(eventName);
              handleSave();
            }}
            isDisabled={
              !name ||
              (capacity === undefined && !isCapacity ? !capacity : false) ||
              errors.nameValue ||
              (errors.numberValue && !isCapacity)
            }
          >
            {t("Create")}
          </Button>
        </>
      )}
    </>
  );
};
