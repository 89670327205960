import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Flex, Button } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { ServiceOptionsModel } from "../../ServicesModel";
import s from "../ServiceModal.module.scss";
import { generateTimeOptions } from "../../utils/generateTimeOptions";
import { useTranslation } from "react-i18next";
import { NameInput } from "../../../../../modalItems/NameInput/NameInput";
import { validateNameValue, ValidationErrors } from "../../../../../modalItems/validations/validations";
import { DurationInput } from "../../../../../modalItems/DurationInput/DurationInput";
import { CostInput } from "../../../../../modalItems/CostInput/CostInput";
import { CloseModalButton } from "../../../../../closeModalButton/closeModalButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (option: ServiceOptionsModel) => void;
  optionToEdit: ServiceOptionsModel | null;
  options: ServiceOptionsModel[];
  setOptions: (options: ServiceOptionsModel[]) => void;
  setOptionToEdit: (option: ServiceOptionsModel | null) => void;
}

export const OptionModal = ({ isOpen, onClose, onSave, optionToEdit, options, setOptions, setOptionToEdit }: Props) => {
  const t = useTranslation().t;
  const timeOptions = generateTimeOptions(true);
  const [name, setName] = useState(optionToEdit?.name || "");
  const [duration, setDuration] = useState(optionToEdit?.optionValues[0]?.duration || timeOptions[0].value);
  const [price, setPrice] = useState<number | string>(optionToEdit?.optionValues[0]?.price || 0);

  const [selectedDurationLabel, setSelectedDurationLabel] = useState<string>(
    timeOptions.find(option => option.value === duration)?.label || timeOptions[0].label,
  );

  const [errors, setErrors] = useState<ValidationErrors>({
    nameValue: false,
    numberValue: false,
  });

  useEffect(() => {
    if (isOpen) {
      const initialDuration = duration || timeOptions[0].value;
      const initialDurationLabel = timeOptions.find(option => option.value === initialDuration)?.label || timeOptions[0].label;
      if (optionToEdit) {
        setDuration(duration);
      } else {
        setSelectedDurationLabel(initialDurationLabel);
      }
      setName(optionToEdit?.name || "");
      setPrice(optionToEdit?.optionValues[0]?.price || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
    setErrors(prevErrors => ({
      ...prevErrors,
      nameValue: validateNameValue(value),
    }));
  };

  const handleDurationChange = (minutes: number, displayValue: string) => {
    setDuration(minutes);
    setSelectedDurationLabel(displayValue);
  };

  const handleChangeCost = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      return setPrice("");
    }

    setPrice(parseInt(e.target.value));
  };

  const handleSave = () => {
    const newOption: ServiceOptionsModel = {
      name: name,
      description: "",
      required: false,
      multichoice: true,
      optionValues: [
        {
          name: name,
          duration: duration,
          price: Number(price) || 0,
          description: "",
          imageUrl: "",
        },
      ],
    };
    onSave(newOption);
    setOptionToEdit(null);
    resetFields();
    onClose();
  };

  const handleCancel = () => {
    setOptionToEdit(null);
    resetFields();
    onClose();
  };

  const handleDelete = () => {
    if (optionToEdit) {
      const updatedOptions = options.filter(option => option.name !== optionToEdit.name);
      setOptions(updatedOptions);
    }
    setOptionToEdit(null);
    resetFields();
    onClose();
  };

  const resetFields = () => {
    setName("");
    setDuration(timeOptions[0].value);
    setPrice("");
    setErrors({
      nameValue: false,
      numberValue: false,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent className={s.modal}>
        <ModalHeader className={s.header}>{t(optionToEdit ? t("Edit an option") : t("Add an option"))}</ModalHeader>
        <CloseModalButton close={handleCancel} width="32px" height="32px" size="lg" />
        <ModalBody className={s.body}>
          <NameInput
            onDelete={() => {
              setName("");
              setErrors(prevErrors => ({
                ...prevErrors,
                nameValue: validateNameValue(""),
              }));
            }}
            value={name}
            onChange={handleNameChange}
            isInvalid={errors.nameValue}
            errorMessage={t("Name can't be empty")}
          />
          <Flex gap="16px" w="100%">
            <DurationInput
              timeOptions={timeOptions}
              selectedDurationLabel={selectedDurationLabel}
              onDurationChange={handleDurationChange}
            />
            <CostInput value={price} onChange={handleChangeCost} />
          </Flex>
        </ModalBody>
        <ModalFooter className={s.footer}>
          {optionToEdit ? (
            <>
              <Button variant="dominoOutlineRed" onClick={handleDelete}>
                {t("Delete")}
              </Button>
              <Button variant="dominoViolet" onClick={handleSave} isDisabled={!name || errors.nameValue}>
                {t("Save")}
              </Button>
            </>
          ) : (
            <>
              <Button variant="dominoOutlineViolet" onClick={handleCancel}>
                {t("Cancel")}
              </Button>
              <Button
                variant="dominoViolet"
                onClick={() => {
                  handleSave();
                }}
                isDisabled={!name || errors.nameValue}
              >
                {t("Create")}
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
