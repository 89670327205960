import {
  Box,
  Text,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import s from "./MainSettings.module.scss";
import { useAppSelector } from "../../../../store";
import { createCalendar, getCalendar, selectCalendar } from "../../CalendarSlice";
import { useAppDispatch } from "@anthill/domino-ui-base";
import { CalendarModel } from "../../CalendarModel";
import { useEffect, useState } from "react";
import WorkingTimeContainerCalendar from "../../../WorkingTimeContainer/WorkingTimeContainerCalendar";
import { useTranslation } from "react-i18next";
import { CloseModalButton } from "../../../closeModalButton/closeModalButton";

export const MainSettings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const calendar = useAppSelector(selectCalendar);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [tempCalendar, setTempCalendar] = useState<CalendarModel>(calendar);

  useEffect(() => {
    dispatch(getCalendar());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTempCalendar(calendar);
  }, [calendar]);

  const onDataChangeCalendar = (updatedCalendar: CalendarModel) => {
    setTempCalendar(updatedCalendar);
  };

  const handleDone = () => {
    dispatch(createCalendar(tempCalendar));
    onClose();
  };

  return (
    <Box className={s.settingsBlock}>
      <Box className={s.header}>{t("Main settings")}</Box>
      <Box className={s.lines}>
        <Box className={s.line}>
          <Flex className={s.lineName} gap="4px">
            <Text fontWeight="500">{t("Working time")}</Text>
            <Text fontSize="13px" color="#8592A3">
              {t("The general schedule of the organization")}
            </Text>
          </Flex>
          <Popover isOpen={isOpen} onClose={onClose} placement="right">
            <PopoverTrigger>
              <Flex maxW="360px">
                {tempCalendar?.schedule?.length === 0 && (
                  <Button
                    onClick={() => {
                      onToggle();
                      onDataChangeCalendar({
                        ...tempCalendar,
                        id: tempCalendar?.id,
                        isAvailable: true,
                        schedule: [
                          {
                            dayOfWeek: "Monday",
                            isAvailable: true,
                            intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                          },
                          {
                            dayOfWeek: "Tuesday",
                            isAvailable: true,
                            intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                          },
                          {
                            dayOfWeek: "Wednesday",
                            isAvailable: true,
                            intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                          },
                          {
                            dayOfWeek: "Thursday",
                            isAvailable: true,
                            intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                          },
                          {
                            dayOfWeek: "Friday",
                            isAvailable: true,
                            intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                          },
                          {
                            dayOfWeek: "Saturday",
                            isAvailable: false,
                            intervals: [],
                          },
                          {
                            dayOfWeek: "Sunday",
                            isAvailable: false,
                            intervals: [],
                          },
                        ],
                      });
                    }}
                    className={s.addButton}
                    minW="360px"
                  >
                    {t("Add a schedule")}
                  </Button>
                )}
                {tempCalendar?.schedule?.length !== 0 && (
                  <Flex flexWrap="wrap" className={s.wrapper} onClick={onToggle} cursor={"pointer"}>
                    <Flex flexDirection="column" w="100%">
                      <Flex flexDirection="column" gap="4px">
                        {tempCalendar?.schedule?.map(el => {
                          if (el.intervals?.length !== 0) {
                            return (
                              <Flex gap="12px" key={el.dayOfWeek}>
                                <Text minW="40px" color="#8592A3">
                                  {t(`${el.dayOfWeek?.slice(0, 3)}`)}
                                </Text>
                                <Flex flexWrap="wrap" gap="4px">
                                  {el.intervals?.map((time, index) => (
                                    <Text key={index} color="#3E5E95">{`${time.beginTime} – ${time.endTime}${
                                      index !== el.intervals?.length - 1 ? "," : ""
                                    }`}</Text>
                                  ))}
                                </Flex>
                              </Flex>
                            );
                          }
                          return null;
                        })}
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </PopoverTrigger>
            <PopoverContent maxW="328px" top="52px" left="10px">
              <PopoverArrow />
              <CloseModalButton close={onClose} width="32px" height="32px" size="lg" top="8px" right="8px" />
              <PopoverHeader p="12px 24px" fontWeight="700">
                {t("Working time")}
              </PopoverHeader>
              <PopoverBody p="24px" maxH="400px" overflow="auto" className={s.popoverBody}>
                {isOpen && <WorkingTimeContainerCalendar onDataChange={onDataChangeCalendar} calendar={tempCalendar} />}
              </PopoverBody>
              <PopoverFooter pt="24px" border="none">
                <Flex justify="space-between">
                  <Button
                    onClick={() =>
                      onDataChangeCalendar({
                        ...tempCalendar,
                        id: tempCalendar?.id,
                        isAvailable: false,
                        schedule: [],
                      })
                    }
                    variant="dominoOutlineRed"
                  >
                    {t("Delete")}
                  </Button>
                  <Button onClick={handleDone} variant="dominoViolet">
                    {t("Done")}
                  </Button>
                </Flex>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
};
