import { App } from "./App";
import "./index.css";
import { BaseApp } from "@anthill/domino-ui-base";
import { DominoCalendarState } from "./ApplicationState";
import { rootReducer } from "./rootReducer";
import { rootSaga } from "./rootSaga";
import "./common/i18next/i18n";

class DominoCalendarApp extends BaseApp<DominoCalendarState> {}

const app = new DominoCalendarApp(rootReducer, rootSaga);
app.start(App);
