import { call, put, takeLatest } from "redux-saga/effects";
import * as EventApi from "./EventApi";
import { handleException, loadingSlice } from "@anthill/domino-ui-base";
import { PayloadAction } from "@reduxjs/toolkit";
import { eventSlice } from "./EventSlice";
import { EventData } from "./EventData";
import { EventModel } from "./EventModel";

export function* eventSaga() {
  yield takeLatest(eventSlice.actions.getEvents, getEvents);
  yield takeLatest(eventSlice.actions.createEvent, createEvent);
  yield takeLatest(eventSlice.actions.updateEvent, updateEvent);
  yield takeLatest(eventSlice.actions.deleteEvent, deleteEvent);
}

export function* getEvents(action: PayloadAction<{ start: string; end: string }>) {
  try {
    yield put(loadingSlice.actions.addLoadingState("getEvent"));
    const data: EventData[] = yield call(EventApi.getEvents, action.payload.start, action.payload.end);
    const model: EventModel[] = data;
    yield put(eventSlice.actions.getEventsCompleted(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(loadingSlice.actions.completedLoadingState("getEvent"));
  }
}

function* createEvent(action: PayloadAction<EventModel>) {
  try {
    const data: EventData = yield call(EventApi.createEvent, action.payload);
    const model: EventModel = data;
    yield put(eventSlice.actions.createEventCompleted(model));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* updateEvent(action: PayloadAction<EventModel>) {
  try {
    yield call(EventApi.updateEvent, action.payload);
    yield put(eventSlice.actions.updateEventCompleted(action.payload));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* deleteEvent(action: PayloadAction<string>) {
  try {
    yield call(EventApi.deleteEvent, action.payload);
    yield put(eventSlice.actions.deleteEventCompleted(action.payload));
  } catch (e: unknown) {
    yield handleException(e);
  }
}
