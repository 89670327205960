import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  InputGroup,
  InputRightElement,
  Checkbox,
  MenuItem,
} from "@chakra-ui/react";
import s from "./RoomModal.module.scss";
import { RoomsModel } from "../RoomsModel";
import { createRoom, deleteRoom, updateRoom } from "../roomsSlice";
import { useAppDispatch, useGA } from "@anthill/domino-ui-base";
import { useEffect, useState } from "react";
import { getServices, selectServices } from "../../ServiceSettings/servicesSlice";
import { useAppSelector } from "../../../../../store";
import { t } from "i18next";
import { NameInput } from "../../../../modalItems/NameInput/NameInput";
import { FooterButtons } from "../../../../modalItems/FooterButtons/FooterButtons";
import { NoteInput } from "../../../../modalItems/NoteInput/NoteInput";
import { validateNameValue, validateNumberValue, ValidationErrors } from "../../../../modalItems/validations/validations";
import SchedulePopover from "./SchedulePopover/SchedulePopover";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/cross.svg";
import { ReactComponent as ArrowDrop } from "../../../../../assets/icons/ArrowDrop.svg";
import { CloseModalButton } from "../../../../closeModalButton/closeModalButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  rooms: RoomsModel;
}

const colors = ["#FFC300", "#FF9900", "#EB5038", "#DC40B0", "#B25BF7", "#0084FF", "#00C2FF", "#4CAF50", "#0BCB6B", "#99CB0B"];

export const RoomModal = ({ isOpen, onClose, rooms }: Props) => {
  const dispatch = useAppDispatch();
  const services = useAppSelector(selectServices);
  const trackEvent = useGA("RoomModal");

  const [name, setName] = useState(rooms.name || "");
  const [description, setDescription] = useState(rooms.description || "");
  const [capacity, setCapacity] = useState(rooms.capacity || 0);
  const [displayValue, setDisplayValue] = useState<string>(rooms.capacity?.toString() || "0");
  const [roomData, setRoomData] = useState<RoomsModel>(rooms);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [selectedColor, setSelectedColor] = useState(rooms.color || "");
  const [note, setNote] = useState(rooms.note || "");
  const [SelectisOpen, setSelectIsOpen] = useState(false);
  const hasServices = services && services.length > 0;

  const handleToggle = () => {
    if (hasServices) {
      setSelectIsOpen(!SelectisOpen);
    }
  };

  const [isScheduleActive, setIsScheduleActive] = useState(rooms.isIndividualSchedule);

  const [errors, setErrors] = useState<ValidationErrors>({
    nameValue: false,
    numberValue: false,
  });

  useEffect(() => {
    if (isOpen) {
      setName(rooms.name || "");
      setDescription(rooms.description || "");
      setCapacity(rooms.capacity || 0);
      setDisplayValue(rooms.capacity?.toString() || "0");
      setSelectedColor(rooms.color || "");
      setSelectedServices(rooms.services || []);
      setRoomData(rooms);
      setNote(rooms.note || "");
      setIsScheduleActive(rooms.isIndividualSchedule || false);
    }
  }, [isOpen, rooms]);

  useEffect(() => {
    dispatch(getServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (services && services?.length > 0) {
      setSelectedServices([services[0].id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  const handleDataChangeRoom = (updatedRoom: RoomsModel) => {
    setRoomData(updatedRoom);
  };

  const handleCheckboxChange = () => {
    setIsScheduleActive(!isScheduleActive);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
    setErrors(prevErrors => ({
      ...prevErrors,
      nameValue: validateNameValue(value),
    }));
  };

  const handleCapacityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value || isNaN(Number(value))) {
      setDisplayValue(value);
      setCapacity(0);
      setErrors(prevErrors => ({
        ...prevErrors,
        numberValue: validateNumberValue(0),
      }));
    } else {
      const numericValue = parseInt(value, 10);
      setDisplayValue(value);
      setCapacity(numericValue);
      setErrors(prevErrors => ({
        ...prevErrors,
        numberValue: validateNumberValue(numericValue),
      }));
    }
  };

  const handleFocus = () => {
    if (displayValue === "0") {
      setDisplayValue("");
    }
  };

  const handleBlur = () => {
    if (displayValue === "") {
      setDisplayValue("0");
    }
  };

  const handleSave = () => {
    const updatedRoom = {
      ...roomData,
      name,
      description,
      capacity,
      services: selectedServices,
      color: selectedColor,
      note,
      schedule: roomData.schedule,
      isIndividualSchedule: isScheduleActive,
    };
    if (roomData.roomId) {
      dispatch(updateRoom(updatedRoom));
    } else {
      dispatch(createRoom(updatedRoom));
    }
    onClose();
    resetFields();
  };

  const handleCancel = () => {
    onClose();
    resetFields();
  };

  const handleDelete = () => {
    if (roomData.roomId) {
      dispatch(deleteRoom({ roomId: roomData.roomId }));
    }
    onClose();
    resetFields();
  };

  const handleSelectService = (serviceId: string) => {
    setSelectedServices(prevSelected =>
      prevSelected.includes(serviceId) ? prevSelected.filter(id => id !== serviceId) : [...prevSelected, serviceId],
    );
  };

  const resetFields = () => {
    if (!rooms.roomId) {
      setRoomData({
        roomId: "",
        name: "",
        description: "",
        color: "",
        schedule: [],
        isAvailable: false,
        capacity: 0,
        imageUrl: "",
        isPromoted: false,
        services: [],
        note: "",
        isIndividualSchedule: false,
      });
      setErrors({
        nameValue: false,
        numberValue: false,
      });
    }
    setErrors({
      nameValue: false,
      numberValue: false,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalContent className={s.modal}>
        <ModalHeader className={s.header}>{roomData.roomId ? t("Edit a wroom") : t("Add a wroom")}</ModalHeader>
        <CloseModalButton close={handleCancel} width="32px" height="32px" size="lg" />
        <ModalBody className={s.body}>
          <NameInput
            onDelete={() => {
              setName("");
              setErrors(prevErrors => ({
                ...prevErrors,
                nameValue: validateNameValue(""),
              }));
            }}
            value={name}
            onChange={handleNameChange}
            isInvalid={errors.nameValue}
            errorMessage={t("Name can't be empty")}
          />
          <Flex className={s.formBox}>
            <Text className={s.label}>{t("Description")}</Text>
            <InputGroup>
              <Input value={description} onChange={e => setDescription(e.target.value)} placeholder={t("Enter a description")} />
              <InputRightElement>
                {description && (
                  <CrossIcon boxSize={1} cursor={"pointer"} className={s.crossIcon} onClick={() => setDescription("")} />
                )}
              </InputRightElement>
            </InputGroup>
          </Flex>
          <Flex className={s.formBox}>
            <Text className={s.label}>{t("Capacity")}</Text>
            <Flex flexDir="column" gap="4px">
              <InputGroup>
                <Input
                  className={s.input}
                  value={displayValue}
                  onChange={handleCapacityChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  type="number"
                  isInvalid={errors.numberValue}
                  errorBorderColor="red.500"
                />
                <InputRightElement>
                  {displayValue !== "0" && (
                    <CrossIcon boxSize={1} cursor={"pointer"} className={s.crossIcon} onClick={() => setDisplayValue("0")} />
                  )}
                </InputRightElement>
              </InputGroup>
              {errors.numberValue && <Text className={s.error}>{t("Capacity can't be empty")}</Text>}
            </Flex>
          </Flex>
          <Flex className={s.formBox}>
            <Text className={s.label}>{t("Color")}</Text>
            <Flex className={s.colors}>
              {colors.map((color, index) => (
                <Flex key={index} className={`${s.colorBlock} ${selectedColor === color ? s.selected : ""}`}>
                  <Flex className={s.color} style={{ backgroundColor: color }} onClick={() => setSelectedColor(color)} />
                </Flex>
              ))}
            </Flex>
          </Flex>
          <SchedulePopover
            roomData={roomData}
            onDataChangeRoom={handleDataChangeRoom}
            isScheduleActive={isScheduleActive}
            handleCheckboxChange={handleCheckboxChange}
          />
          <Flex className={s.formBox}>
            <Text className={s.label}>{t("Services")}</Text>
            <Menu
              isOpen={SelectisOpen}
              onOpen={() => handleToggle()}
              onClose={() => setSelectIsOpen(false)}
              closeOnSelect={false}
              matchWidth
            >
              <MenuButton
                as={Button}
                variant="dominoDefaultMenuBtn"
                _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
                rightIcon={<ArrowDrop />}
                onClick={handleToggle}
                isDisabled={!hasServices}
              >
                {services && selectedServices.length > 0 ? (
                  services
                    .filter(service => selectedServices.includes(service.id))
                    .map(service => service.name)
                    .join(", ")
                ) : (
                  <Text color="#8592A3">{t("Select services")}</Text>
                )}
              </MenuButton>
              <MenuList
                maxH="170px"
                overflow="auto"
                css={{
                  "&::-webkit-scrollbar": {
                    width: "0",
                  },
                }}
              >
                {hasServices ? (
                  services.map(service => (
                    <MenuItem key={service.id}>
                      <Checkbox
                        isChecked={selectedServices.includes(service.id)}
                        onChange={() => handleSelectService(service.id)}
                        cursor="pointer"
                        variant="dominoGreen"
                        p="4px 0 4px"
                      >
                        <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxW="330px">
                          {service.name}
                        </Text>
                      </Checkbox>
                    </MenuItem>
                  ))
                ) : (
                  <Text p={4} textAlign="center">
                    No services available
                  </Text>
                )}
              </MenuList>
            </Menu>
          </Flex>
          <NoteInput onDelete={() => setNote("")} value={note} onChange={e => setNote(e.target.value)} />
        </ModalBody>
        <ModalFooter className={s.footer}>
          <FooterButtons
            id={roomData.roomId}
            handleSave={handleSave}
            handleDelete={handleDelete}
            handleCancel={handleCancel}
            name={name}
            capacity={capacity}
            errors={errors}
            trackEvent={trackEvent}
            eventName="createRoom"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
