import { baseApi } from "@anthill/domino-ui-base";
import { ServicesModel } from "./ServicesModel";

export const getServices = () => {
  return baseApi.httpGet(`/services`);
};

export const createService = (services: ServicesModel) => {
  return baseApi.httpPost(`/services`, services);
};

export const getCurrentService = (servicesId: string) => {
  return baseApi.httpGet(`/services/${servicesId}`);
};

export const updateService = (service: ServicesModel) => {
  return baseApi.httpPut(`/services/${service.id}`, service);
};

export const deleteService = (serviceId: string) => {
  return baseApi.httpDelete(`/services/${serviceId}`);
};
