import { Link } from "react-router-dom";
import { Button, ChakraProvider, CloseButton, Flex, Icon, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { ProcessedEvent } from "@anthill/react-scheduler/types";
import { BaseAppSettings, theme, useAppDispatch } from "@anthill/domino-ui-base";
import { EventStatus } from "./components/EventStatus";
import { deleteEvent, selectEvents } from "../Event/EventSlice";
import { formatOptions } from "./utils/formatOptions";
import { getTotalPrice } from "./utils/getTotalPrice";
import { formatDate } from "./utils/formatDate";
import { ReactComponent as linkOut } from "../../assets/icons/linkOut.svg";
import styles from "./EventPopover.module.scss";
import { useState } from "react";
import { EventModal } from "../Event/components/EventModal/EventModal";
import { useAppSelector } from "../../store";
import { RoomsModel } from "../CreateCalendar/components/RoomSettings/RoomsModel";

declare const appSettings: BaseAppSettings;

interface EventPopoverProps {
  event: ProcessedEvent;
  close: () => void;
  rooms: RoomsModel[];
}

export const EventPopover = ({ event, close, rooms }: EventPopoverProps) => {
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const events = useAppSelector(selectEvents);
  const eventProp = events.find(evt => evt.event_id === event.event_id);
  const roomName = rooms.find(room => room.roomId === event.roomId)?.name || "";
  const roomDescription = rooms.find(room => room.roomId === event.roomId)?.description || "";

  const isUserLink = event.contact.conversationId ? (
    <Link target="_blank" to={`${appSettings.idpUrl}/chats/${event.contact.conversationId}`} className={styles.link}>
      {event.contact.username}
      <Icon as={linkOut} className={styles.icon} />
    </Link>
  ) : (
    event.contact.username
  );

  const datas: Record<string, string | JSX.Element> = {
    [t("Client")]: isUserLink,
    [t("Cost")]: getTotalPrice(event.services),
    [t("Options")]: formatOptions(event.services.length > 0 && event.services[0].options),
    [t("Note")]: event.note,
  };
  const formattedDate = formatDate(event.start, event.end);

  const handleOpen = () => {
    setIsModalShown(true);
  };
  const handleClose = () => {
    setIsModalShown(false);
  };
  const handleDelete = () => {
    dispatch(deleteEvent(event.event_id as "string"));
  };

  return (
    <ChakraProvider theme={theme}>
      <Flex direction="column" minWidth={"328px"} padding={"12px 16px 16px"} pos="relative" rowGap="16px">
        <Flex direction="column" rowGap="8px">
          <Flex alignItems="center" justifyContent="space-between" pr={"24px"}>
            <Text fontSize="18px" lineHeight="24px" color="newBlack">
              {event.title}
            </Text>
            <EventStatus status={event.status} />
          </Flex>
          <Text fontSize="15px" lineHeight="20px" color="newBlack">
            {formattedDate}
          </Text>
        </Flex>
        <Flex direction="column" gap="16px">
          <Flex direction="column" gap="8px">
            {Object.keys(datas)
              ?.slice(0, 1)
              .map(key => {
                return (
                  datas[key] && (
                    <Flex direction="column" gap="4px" key={key}>
                      <Text color="darkGrey" fontSize="13px" lineHeight="16px">
                        {key}
                      </Text>
                      <Text color="newBlack" fontSize="15px" lineHeight="20px">
                        {datas[key]}
                      </Text>
                    </Flex>
                  )
                );
              })}
            {(roomName || roomDescription) && (
              <Flex direction="row" gap="16px" w="100%">
                {roomName && (
                  <Flex flexDir="column" gap="4px" maxW="150px">
                    <Text color="darkGrey" fontSize="13px" lineHeight="16px">
                      {t("Wroom")}
                    </Text>
                    <Text
                      color="newBlack"
                      fontSize="15px"
                      lineHeight="20px"
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                    >
                      {roomName}
                    </Text>
                  </Flex>
                )}
                {roomDescription && (
                  <Flex flexDir="column" gap="4px" maxW="200px">
                    <Text color="darkGrey" fontSize="13px" lineHeight="16px">
                      {t("Description")}
                    </Text>
                    <Text
                      color="newBlack"
                      fontSize="15px"
                      lineHeight="20px"
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                    >
                      {roomDescription}
                    </Text>
                  </Flex>
                )}
              </Flex>
            )}
            {Object.keys(datas)
              ?.slice(1)
              .map(key => {
                return (
                  datas[key] && (
                    <Flex direction="column" gap="4px" key={key}>
                      <Text color="darkGrey" fontSize="13px" lineHeight="16px">
                        {key}
                      </Text>
                      <Text color="newBlack" fontSize="15px" lineHeight="20px">
                        {datas[key]}
                      </Text>
                    </Flex>
                  )
                );
              })}
          </Flex>
          <Flex direction="row" justifyContent="space-between">
            <Button variant="dominoOutlineRed" onClick={handleDelete}>
              {t("Delete")}
            </Button>
            <Button variant="dominoViolet" onClick={handleOpen}>
              {t("Change")}
            </Button>
          </Flex>
        </Flex>
        <CloseButton
          onClick={close}
          pos="absolute"
          top="4px"
          right="4px"
          w="24px"
          h="24px"
          color="darkGrey"
          borderRadius="50%"
          _hover={{ bgColor: "lightDeepBlue", color: "mainPurple" }}
        />
      </Flex>
      {isModalShown && <EventModal event={eventProp ?? null} onCancel={handleClose} rooms={rooms} />}
    </ChakraProvider>
  );
};
