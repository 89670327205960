import { Loader, useAppDispatch } from "@anthill/domino-ui-base";
import { useAppSelector } from "../../store";
import { getCalendar, selectCalendar, selectIsLoading } from "../CreateCalendar/CalendarSlice";
import { useEffect } from "react";
import { EmptyListView } from "../emptyView/EmptyListView";
import { SchedulerPage } from "../Scheduler/SchedulerPage";
import { getRooms, selectRooms } from "../CreateCalendar/components/RoomSettings/roomsSlice";
import { t } from "i18next";

export const HomePage = () => {
  const dispatch = useAppDispatch();
  const calendar = useAppSelector(selectCalendar);
  const isLoading = useAppSelector(selectIsLoading);
  const rooms = useAppSelector(selectRooms);

  useEffect(() => {
    dispatch(getCalendar());
    if (calendar) {
      dispatch(getRooms());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (isLoading) {
    return <Loader scope={"getCalendar"} />;
  }

  return (
    <>
      {calendar && calendar?.schedule?.length !== 0 && rooms.length !== 0 ? (
        <SchedulerPage rooms={rooms} schedule={calendar.schedule} />
      ) : (
        <EmptyListView
          title={t("You haven't added a calendar yet")}
          description={t("To get started, create an organization's work calendar")}
        />
      )}
    </>
  );
};
