import { Button, Flex, Grid, GridItem, Text, Box, Icon } from "@chakra-ui/react";
import { ReactComponent as CalendarImageStart } from "../../assets/images/calendarImageStart.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ButtonPlus } from "../../assets/icons/ButtonPlus.svg";

interface Props {
  title: string;
  description: string;
}

export const EmptyListView = ({ title, description }: Props) => {
  const nav = useNavigate();
  const onClick = () => {
    nav("/calendar");
  };

  return (
    <Grid
      w="100%"
      h={"100vh"}
      templateColumns={{ base: "1fr", md: "45% 55%", xl: "40% 60%" }}
      overflow={{ base: "auto", md: "hidden" }}
      alignItems="center"
      display={{ base: "flex", md: "grid" }}
      flexDirection={"column-reverse"}
      justifyContent={"center"}
      background="#F7FAFF"
    >
      <GridItem display="flex" alignItems="center" justifyContent="center" p={"0 32px"}>
        <Flex direction="column" maxW="380px" w={"100%"} textAlign={{ base: "center", md: "left" }}>
          <Text mb={"15px"} variant="h2" color="#232B39">
            {title}
          </Text>
          <Text variant="medium" color="darkGrey">
            {description}
          </Text>
          <Flex
            flexDirection={["column", "column", "column", "column", "row"]}
            alignItems={{ base: "center", md: "flex-start" }}
            mt={"20px"}
            gap="24px"
          >
            <Button variant="dominoViolet" data-pw="create-button" onClick={onClick} gap="4px" minW="205px">
              <Icon as={ButtonPlus} />
              Создать календарь
            </Button>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem h={{ md: "100%" }} display="flex" justifyContent="center" alignItems="center" p={"0 32px"} background="#F7FAFF">
        <Box
          background="#F7FAFF"
          as={CalendarImageStart}
          maxW={{ base: "240px", md: "100%" }}
          mb={{ base: "16px", md: "0" }}
          height={"max-content"}
        />
      </GridItem>
    </Grid>
  );
};
