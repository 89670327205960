import { t } from "i18next";
import { ServicesModel } from "../../CreateCalendar/components/ServiceSettings/ServicesModel";
import { formatPriceWithSpaces } from "../../../utils/formatPriceWithSpaces";

export function getTotalPrice(services: ServicesModel[]): string {
  const totalPrice =
    services?.length > 0
      ? services[0]?.options?.reduce((acc, cur) => {
          return acc + cur?.optionValues?.reduce((acc, cur) => acc + cur?.price, 0);
        }, services[0]?.price)
      : 0;
  const formattedPrice = formatPriceWithSpaces(totalPrice);

  return `${formattedPrice} ${t("$")}`;
}
