import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RoomsModel } from "./RoomsModel";
import { RoomsState } from "./RoomsState";
import { ApplicationState } from "../../../../ApplicationState";

const initialState: RoomsState = {
  rooms: [],
};

export const roomsSlice = createSlice({
  name: "roomsSlice",
  initialState,
  reducers: {
    getRooms: state => {
      return {
        ...state,
      };
    },
    getRoomsCompleted: (state, action: PayloadAction<RoomsModel[]>) => {
      return {
        ...state,
        rooms: action.payload,
      };
    },
    createRoom: (state, action: PayloadAction<RoomsModel>) => {
      return {
        ...state,
        newRoom: action.payload,
      };
    },
    getCurrentRoom: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    getCurrentRoomCompleted: (state, action: PayloadAction<RoomsModel>) => {
      return {
        ...state,
        currentRoom: action.payload,
      };
    },
    updateRoom: (state, action: PayloadAction<RoomsModel>) => {
      return {
        ...state,
      };
    },
    deleteRoom: (state, action: PayloadAction<{ roomId: string }>) => {
      return {
        ...state,
      };
    },
    clearRoom: state => {
      return {
        ...state,
        currentRoom: undefined,
      };
    },
  },
});

export const { getRooms, createRoom, getCurrentRoom, updateRoom, clearRoom, deleteRoom } = roomsSlice.actions;

export const selectRooms = (state: ApplicationState) => state.app.roomsState.rooms;
export const selectCurrentRoom = (state: ApplicationState) => state.app.roomsState.currentRoom;
