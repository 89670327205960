import { baseApi } from "@anthill/domino-ui-base";
import { RoomsModel } from "./RoomsModel";

export const getRooms = () => {
  return baseApi.httpGet(`/rooms`);
};

export const createRoom = (room: RoomsModel) => {
  return baseApi.httpPost(`/rooms`, room);
};

export const getCurrentRoom = (roomId: string) => {
  return baseApi.httpGet(`/rooms/${roomId}`);
};

export const updateRoom = (room: RoomsModel) => {
  return baseApi.httpPut(`/rooms/${room.roomId}`, room);
};

export const deleteRoom = (roomId: string) => {
  return baseApi.httpDelete(`/rooms/${roomId}`);
};
