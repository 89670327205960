import { t } from "i18next";
import { formatSingleDigit } from "./formatSingleDigit";

const MONTHS_FORMS = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

export function formatDate(start: Date, end: Date): string {
  const from = `${formatSingleDigit(start.getHours())}:${formatSingleDigit(start.getMinutes())}`;
  const to = `${formatSingleDigit(end.getHours())}:${formatSingleDigit(end.getMinutes())}`;
  const day = formatSingleDigit(end.getDate());
  const month = t(`${MONTHS_FORMS[end.getMonth()]} gen`);
  const year = end.getFullYear();

  return `${from}–${to}, ${day} ${month} ${year}`;
}
