import { useRef, useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { Scheduler } from "@anthill/react-scheduler";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SchedulerHeader } from "./ShedulerHeader/ShedulerHeader";
import { SchedulerRef } from "@anthill/react-scheduler/types";
import "./test.css";
import { RoomsModel } from "../CreateCalendar/components/RoomSettings/RoomsModel";
import { t } from "i18next";
import { Translations } from "./translations";
import { EventModal } from "../Event/components/EventModal/EventModal";
import { useAppDispatch } from "@anthill/domino-ui-base";
import { useAppSelector } from "../../store";
import { getEvents, selectEvents } from "../Event/EventSlice";
import { EventPopover } from "../EventPopover/EventPopover";
import { SelectedRange } from "@anthill/react-scheduler/store/types";
import { calculateWorkHours, formatDate, getWorkDays } from "./utils/utils";
import { ScheduleModel } from "../CreateCalendar/CalendarModel";
import { MonthProps } from "@anthill/react-scheduler/views/Month";
import { WeekProps } from "@anthill/react-scheduler/views/Week";
import { DayProps } from "@anthill/react-scheduler/views/Day";

interface Props {
  rooms: RoomsModel[];
  schedule: ScheduleModel[];
}

const WEEK_START_ON = 0;
const CELL_STEP = 60;

export const SchedulerPage = ({ rooms, schedule }: Props) => {
  const dispatch = useAppDispatch();
  const events = useAppSelector(selectEvents);
  const calendarRef = useRef<SchedulerRef>(null);

  const isInit = useRef<boolean>(false);
  const [range, setRange] = useState<SelectedRange>({ start: new Date(), end: new Date() });
  useEffect(() => {
    if (isInit.current) {
      dispatch(getEvents({ start: formatDate(range.start), end: formatDate(range.end) }));
    } else {
      isInit.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  const eventData = events.map(event => ({
    ...event,
    start: new Date(event.start),
    end: new Date(event.end),
  }));

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3E5E95",
      },
    },
  });

  const translations: Translations = {
    navigation: {
      month: t("Month"),
      week: t("Week"),
      day: t("Day"),
      agenda: "Agenda",
      today: t("Today"),
      selectRooms: t("Select wroom"),
    },
    form: {
      addTitle: "Add Event",
      editTitle: "Edit Event",
      confirm: "Confirm",
      delete: "Delete",
      cancel: "Cancel",
    },
    event: {
      title: "Title",
      subtitle: "Subtitle",
      start: "Start",
      end: "End",
      allDay: "All Day",
    },
    moreEvents: "More...",
    loading: "Loading...",
    noDataToDisplay: "No data to display",
  };

  const { startHour, endHour } = calculateWorkHours(schedule);
  const weekDays = getWorkDays(schedule);
  const monthSchedule: MonthProps = {
    weekDays: weekDays,
    startHour,
    endHour,
    weekStartOn: WEEK_START_ON,
  };
  const weekSchedule: WeekProps = {
    weekDays: weekDays,
    startHour,
    endHour,
    step: CELL_STEP,
    weekStartOn: WEEK_START_ON,
  };
  const daySchedule: DayProps = {
    startHour,
    endHour,
    step: CELL_STEP,
  };

  return (
    <Box height="100dvh" pos="relative" overflowY="auto">
      <SchedulerHeader calendarRef={calendarRef} rooms={rooms} />
      <Box overflowY="auto">
        <ThemeProvider theme={theme}>
          <Scheduler
            agenda={false}
            ref={calendarRef}
            events={eventData}
            translations={translations}
            setRange={setRange}
            month={monthSchedule}
            week={weekSchedule}
            day={daySchedule}
            resources={rooms}
            resourceFields={{
              idField: "roomId",
              textField: "name",
              subTextField: "description",
              avatarField: "title",
              colorField: "color",
              contactName: "contactName",
            }}
            customEditor={schedulerData => (
              <EventModal schedulerData={schedulerData} event={null} rooms={rooms} onCancel={schedulerData.close} />
            )}
            customViewer={(event, close) => <EventPopover rooms={rooms} event={event} close={close} />}
          />
        </ThemeProvider>
      </Box>
    </Box>
  );
};
