import { call, put, select, takeLatest } from "redux-saga/effects";
import * as RoomsApi from "./RoomsApi";
import { roomsSlice, selectRooms } from "./roomsSlice";
import { RoomsData } from "./RoomsData";
import { RoomsModel } from "./RoomsModel";
import { PayloadAction } from "@reduxjs/toolkit";
import { handleException, loadingSlice } from "@anthill/domino-ui-base";

export function* roomsSaga() {
  yield takeLatest(roomsSlice.actions.getRooms, getRooms);
  yield takeLatest(roomsSlice.actions.createRoom, createRoom);
  yield takeLatest(roomsSlice.actions.updateRoom, updateRoom);
  yield takeLatest(roomsSlice.actions.getCurrentRoom, getCurrentRoom);
  yield takeLatest(roomsSlice.actions.deleteRoom, deleteRoom);
}

export function* getRooms() {
  try {
    yield put(loadingSlice.actions.addLoadingState("getRooms"));
    const data: RoomsData[] = yield call(RoomsApi.getRooms);
    const model: RoomsModel[] = [...data];
    if (model.length > 0) {
      yield put(roomsSlice.actions.getRoomsCompleted(model));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(loadingSlice.actions.completedLoadingState("getRooms"));
  }
}

function* createRoom(action: PayloadAction<RoomsModel>) {
  try {
    const newRoom: RoomsModel = yield call(RoomsApi.createRoom, action.payload);
    const currentRooms: RoomsModel[] = yield select(selectRooms);
    const updatedRooms = [...(currentRooms || []), newRoom];
    yield put(roomsSlice.actions.getRoomsCompleted(updatedRooms));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* getCurrentRoom(action: PayloadAction<string>) {
  try {
    const data: RoomsData = yield call(RoomsApi.getCurrentRoom, action.payload);
    const model: RoomsModel = data;
    yield put(roomsSlice.actions.getCurrentRoomCompleted(model));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* updateRoom(action: PayloadAction<RoomsModel>) {
  try {
    yield call(RoomsApi.updateRoom, action.payload);
    const currentRooms: RoomsModel[] = yield select(selectRooms);
    const updatedRooms = currentRooms.map(room => (room.roomId === action.payload.roomId ? action.payload : room));
    yield put(roomsSlice.actions.getRoomsCompleted(updatedRooms));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* deleteRoom(action: PayloadAction<{ roomId: string }>) {
  try {
    yield call(RoomsApi.deleteRoom, action.payload.roomId);
    const currentRooms: RoomsModel[] = yield select(selectRooms);
    const updatedRooms = currentRooms.filter(room => room.roomId !== action.payload.roomId);
    yield put(roomsSlice.actions.getRoomsCompleted(updatedRooms));
  } catch (e: unknown) {
    yield handleException(e);
  }
}
